import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Status, Date } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getDynamicForm as onGetDynamicForm,
  addDynamicForm as onaddDynamicForm,
  updateDynamicForm as onUpdateDynamicForm,
  deleteDynamicForm as onDeleteDynamicForm,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import moment from "moment";

//redux
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

const ContactsList = props => {

  //meta title
  document.title = "Referans Kodu | Kupon ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEdit, setEdit] = useState();
  const [getCategoryCompany, setCategoryCompany] = useState([]);

  const [getCompanyID, setCompanyID] = useState(null);
  const [getIsActive, setIsActive] = useState(false);
  const [selectedValuesCompany, setSelectedValuesCompany] = useState([]);
  const [getSelectedValuesCompanyDefault, setSelectedValuesCompanyDefault] = useState([]);

  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      referanceCode: (getEdit && getEdit.Referance_Code_Code) || "",
    },
    validationSchema: Yup.object({
      referanceCode: Yup.string().required("Başlangıç Yaşınızı Giriniz"),
    }),
    onSubmit: values => {
      var localDate = moment().local();

      if (isEdit) {
        const updateUser = {
          ID: getEdit.ID,
          Referance_Code_Code:values.referanceCode,
          Company_ID: getCompanyID,
          IsActive:getIsActive
                };
        // update user
        dispatch(onUpdateDynamicForm(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Referance_Code_Code:values["referanceCode"],
          Created_DateTime:localDate,
          Company_ID: getCompanyID,
          IsActive:getIsActive
        };
        // save new user
        dispatch(onaddDynamicForm(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const _getAuthDataCompany = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getCompanyKuponSearch/select/`)
      .then((res) => {
        setCategoryCompany(res.data)
      })
  }

  const { users } = useSelector(state => ({
    users: state.contacts.dynamic,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Kod",
        accessor: "Referance_Code_Code",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
    

      {
        Header: "Firma",
        accessor: "Company.Company_Title",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: 'Oluşturulma Tarihi',
        accessor: 'Created_DateTime',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },


      {
        Header: "Aktif Mi?",
        accessor: "IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      

      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
             

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetDynamicForm());
      setIsEdit(false);
   }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
    _getAuthDataCompany()

  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setContact(user);
    setIsEdit(true);


    setIsActive(user.IsActive)
    setCompanyID(user.Company_ID)
    setEdit(user)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteDynamicForm(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  const handleSelectChangeCompany = (arg) => {
    setCompanyID(arg.target.value)
  }


  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);


  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Kullanıcının girdiği metne göre seçenekleri filtrele
    const filtered = getCategoryCompany.filter(option =>
        option.Company_Title.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredOptions(filtered);
};

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Referans Kodu" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddDynamicForm={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Referans Kodu Düzenle" : "Referans Kodu Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Kod</Label>
                              <Input
                                name="referanceCode"
                                type="text"
                                placeholder="Kod"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.referanceCode || ""}
                                invalid={
                                  validation.touched.referanceCode &&
                                    validation.errors.referanceCode
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.referanceCode &&
                                validation.errors.referanceCode ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.referanceCode}
                                </FormFeedback>
                              ) : null}
                            </div>
                       

                            <div className="mb-3">
                              <Label className="form-label">Firma Seçiniz</Label>

                              <input
                type="text"
                className="form-control"
                placeholder="Arama yapın..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCompanyID}
                               
                                onChange={(text) => handleSelectChangeCompany(text)}
                                value={
                                  getCompanyID
                                }>
                                                                 <option value="">Lütfen Seçim Yapınız</option>

                                {filteredOptions.length > 0 ? filteredOptions.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Company_Title} - {item.Users.length}</option>
                          )) :  getCategoryCompany.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Company_Title} - {item.Users.length}</option>
                          ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                      <div className="d-flex">
                              <label htmlFor="resume">Aktif Mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  className="switch"
                                  defaultChecked={getIsActive}
                                  onChange={() =>
                                    setIsActive(!getIsActive)
                                  }
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div>

                   
                     
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
