import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Status } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";
import {ExportToExcel2} from '../../../ExportToExcel2'
import DateModal from "components/Common/DateModal";


import Dropzone from 'react-dropzone-uploader';


import {
  getCompany as onGetCompany,
  addCompany as onAddCompany,
  updateCompany as onUpdateCompany,
  deleteCompany as onDeleteCompany,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "FİRMALAR | KUPON ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getFileName, setFileName] = useState('');



  const [getIsActive, setIsActive] = useState(false);
  const [getIsPrivate, setIsPrivate] = useState(false);
  const [getSelectCity, setSelectCity] = useState(null);
  const [getChoicegetDist, setChoicegetDist] = useState(null);
  const [getCityID, setCityID] = useState(null);
  const [getDistID, setDistID] = useState(null);


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Company_Title) || "",
      brand: (contactEdit && contactEdit.Brand_Name) || "",
      lat: (contactEdit && contactEdit.Lat) || "",
      long: (contactEdit && contactEdit.Long) || "",
      iban: (contactEdit && contactEdit.Iban) || "",
      taxnumber: (contactEdit && contactEdit.TaxNumber) || "",
      numberofemployees: (contactEdit && contactEdit.NumberOfEmployees) || "",
      address: (contactEdit && contactEdit.Company_Adress) || "",
      companydescription: (contactEdit && contactEdit.Company_Desc) || "",
      taxAdministration: (contactEdit && contactEdit.TaxAdministration) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),


    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Company_Title: values.title,
          Company_Logo: getFileName,
          Brand_Name: values.brand,
          Lat: parseFloat(values.lat),
          Long: parseFloat(values.long),
          Iban: values.iban,
          TaxNumber: values.taxnumber.toString(),
          NumberOfEmployees: parseInt(values.numberofemployees),
          Company_City: getSelectCity,
          Company_Dist: getChoicegetDist,
          Company_Adress: values.address,
          TaxAdministration: values.taxAdministration,
          Company_Desc: values.companydescription,
          PrivateSector: getIsPrivate,
          Active: getIsActive,
          Campain_SubCategory_ID: getCategoryID,
          City_ID: getCityID,
          Dist_ID: getDistID
        };
        dispatch(onUpdateCompany(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {


        const newUser = {
         Company_Title: values["title"],
        Company_Logo: getFileName,
        Brand_Name: values["brand"],
          Lat: parseFloat(values["lat"]),
          Long: parseFloat(values["long"]),
          Iban: values["iban"],
          TaxNumber: values["taxnumber"].toString(),
          NumberOfEmployees: parseInt(values["numberofemployees"]),
          Company_City: getSelectCity,
          Company_Dist: getChoicegetDist,
          Company_Adress: values["address"],
          Company_Desc: values["companydescription"],
          PrivateSector: getIsPrivate,
          Active: getIsActive,
          Campain_SubCategory_ID: getCategoryID,
          TaxAdministration: values["taxAdministration"],
          City_ID: getCityID,
          Dist_ID: getDistID
        };
        // save new user
        dispatch(onAddCompany(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.company,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);


  const [getCategoryID, setCategoryID] = useState(null);


  const [getCategory, setCategory] = useState([]);
  const [getListCity, setListCity] = useState([]);
  const [getListDisct, setListDisct] = useState([]);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "ID",
        accessor: "ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Company_Logo ? (
              <div className="avatar-xs">
                {cellProps.Company_Title != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Company_Title.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.Company_Logo}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Firma Adı",
        accessor: "Company_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Kullanıcı Sayısı",
        filterable: true,
         accessor: (cellProps) => (
          <>
           
          { cellProps.Users != null ?  <div >
                

                <span >
                  
                  {cellProps.Users.length}
                </span>

              </div> : null}
             
          </>
        ),
      },
/** 
      {
        Header: "Kullanılan Kupon",
        filterable: true,
         accessor: (cellProps) => (
          <>
           
          { cellProps.Campain_History_Company != null ?  <div >
                

                <span >
                  
                  {cellProps.Campain_History_Company.length}
                </span>

              </div> : null}
             
          </>
        ),
      },
    */
      
      {
        Header: "Şehir",
        accessor: "Company_City",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "İlçe",
        accessor: "Company_Dist",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Alt Sektör",
        accessor: "Campain_SubCategory.Campain_SubCategory_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Aktiflik Durumu",
        accessor: "Active",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },

      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">


              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _setChoiceCity  = async(arg) => {
    setListDisct([])

    setCityID(arg.target.value)
    
    let filterData = getListCity.filter((x)=> x.iso2 == arg.target.value)

    setSelectCity(filterData[0].name)
        
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getDistrict/TR/${arg.target.value}`)
    .then((res) => {
      res.data.map((item, index) => (

        
        setListDisct(oldArray => [...oldArray, item] )

        //console.log("lsdfkl",item)

      ))
       })

  }

  const _setChoiceCityLoading  = async(arg) => {

    setListDisct([])

    
    let filterData = getListCity.filter((x)=> x.iso2 == arg)

        
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getDistrict/TR/${arg}`)
    .then((res) => {
      res.data.map((item, index) => (

        
        setListDisct(oldArray => [...oldArray, item] )

        //console.log("lsdfkl",item)

      ))
       })

  }


  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getSubCategoryKupon/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }



  const _getCity= async (city,CN) => {
    try {
  
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getCity/TR`)
    .then((res) => {
  
      let newCityList = res.data.sort(function (a, b) {
        var atitle = a.name;
        var btitle = b.name;
        var alfabe = "0123456789AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz";
        if (atitle.length === 0 || btitle.length === 0) {
            return atitle.length - btitle.length;
        }
        for (var i = 0; i < atitle.length && i < btitle.length; i++) {
            var ai = alfabe.indexOf(atitle[i].toUpperCase());
            var bi = alfabe.indexOf(btitle[i].toUpperCase());
            if (ai !== bi) {
                return ai - bi;
            }
        }
      })
  
      newCityList.map((item, index) => (
      
        setListCity(oldArray => [...oldArray, item] )

      ))
       })
       
      } catch (err) {
        console.log("_getCity",err)
      }
  }

  useEffect(() => {
    _getCity()
    _getCampainHistory()
    // setIsEdit(false);
  }, []);

  const _getCampainHistory= async (city,CN) => {
    try {
  
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getCampainHistory/select/`)
    .then((res) => {
  
        setCampainHistory(res.data)
       })
       
      } catch (err) {
        console.log("_getCity",err)
      }
  }

  useEffect(() => {
    _getAuthData()
    if (users && !users.length) {
      dispatch(onGetCompany());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };



  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    setCategoryID(user.Campain_SubCategory_ID)

    setFileName(user.Company_Logo)
  
      setIsActive(user.Active)
 
    setIsPrivate(user.PrivateSector)
   

    setSelectCity(user.Company_City)

    setCityID(user.City_ID)

    _setChoiceCityLoading(user.City_ID)

    setDistID(user.Dist_ID)
    setChoicegetDist(user.Company_Dist)
    setIsEdit(true);

    

    toggle();
  };




  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteCompany(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const _setChoicegetDist = (arg) => {

    setDistID(arg.target.value)

    let filterData = getListDisct.filter((x)=> x.id == arg.target.value)
    setChoicegetDist(filterData[0].name)

  };

 

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }


  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }
  const [getSelectDateFinish, setSelectDateFinish] = useState(null);
  const [getSelectDate, setSelectDate] = useState(null);
  const [getCampainHistory, setCampainHistory] = useState(null);

  const exportToCSV = () => {

        const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = getSelectDate + " " + getSelectDateFinish
    let getSelectDateDaysFinish = moment(getSelectDateFinish).add(1, 'days').format('YYYY/MM/DD')


    const data2 = getCampainHistory.filter(x => x.Created_DateTime > getSelectDate && x.Created_DateTime < getSelectDateDaysFinish).map(elt => [
      elt.Campain.Campain_Title,
      elt.Price,
      elt.Company.Company_Title
    ]);
    
    const ws = XLSX.utils.json_to_sheet(data2);
    
    ws["A1"] = { v: "Kampanya Başlık", t: "s" };
    ws["B1"] = { v: "Fiyat", t: "s" };
    ws["C1"] = { v: "Firma", t: "s" };
    
    
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }
  const [getDateClick, setDateClick] = useState(null);
  const [getExcelModal, setExcelModal] = useState(null);

  const onClickExcel = (order) => {
    setDateClick(order);
    setExcelModal(true);
  }

  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }


  return (
    <React.Fragment>

<DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}
        onFilterClick={exportToCSV}
        show={getExcelModal}
        onCloseClick={() => setExcelModal(false)}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Firma Listesi" />

          <ExportToExcel2 title={"Tarih Excel"} onDownloadClick={exportToCSV} onClickExcel={onClickExcel} apiData={users} fileName={"fileName"} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddCompany={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                
                  <Modal size="lg" isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Firma Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                         

                          <Col lg={6}>
                          <div className="mb-3">
                              <Label className="form-label">Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Firma Başlığını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Marka Adı</Label>
                              <Input
                                name="brand"
                                label="brand"
                                type="brand"
                                placeholder="Marka Adı Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.brand || ""}
                                invalid={
                                  validation.touched.brand &&
                                    validation.errors.brand
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.brand &&
                                validation.errors.brand ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.brand}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Alt Sektör Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                              <option value="">Lütfen Seçim Yapınız</option>
                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Campain_SubCategory_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Firma Açıklama</Label>
                              <Input
                                name="companydescription"
                                label="companydescription"
                                type="companydescription"
                                placeholder="Firma açıklama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.companydescription || ""}
                                invalid={
                                  validation.touched.companydescription &&
                                    validation.errors.companydescription
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.companydescription &&
                                validation.errors.companydescription ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.companydescription}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                            
                           
                              <Col  lg={6} className="mb-3">
                                <label htmlFor="resume">Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>
                         

                            <Col lg={6} className="mb-3">
                            <div>
                <img
                  className="avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + getFileName}
                  alt=""
                />
              </div>
              </Col>


                   

                     
                            <Col lg={6}>
                       
                            <div className="mb-3">
                              <Label className="form-label">Vergi Numarası</Label>
                              <Input
                                name="taxnumber"
                                label="taxnumber"
                                type="number"
                                placeholder="Vergi Numarası Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.taxnumber || ""}
                                invalid={
                                  validation.touched.taxnumber &&
                                    validation.errors.taxnumber
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.taxnumber &&
                                validation.errors.taxnumber ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.taxnumber}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                            <Col lg={6}>

                            <div className="mb-3">
                              <Label className="form-label">Vergi Dairesi</Label>
                              <Input
                                name="taxAdministration"
                                label="taxAdministration"
                                type="text"
                                placeholder="Vergi Dairesi Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.taxAdministration || ""}
                                step="0.01" // Step değerini istediğiniz hassasiyete göre ayarlayabilirsiniz

                                invalid={
                                  validation.touched.taxAdministration &&
                                    validation.errors.taxAdministration
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.taxAdministration &&
                                validation.errors.taxAdministration ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.taxAdministration}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                        

                            <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Enlem</Label>
                              <Input
                                name="lat"
                                label="lat"
                                type="text"
                                placeholder="Enlem Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lat || ""}
                                invalid={
                                  validation.touched.lat &&
                                    validation.errors.lat
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lat &&
                                validation.errors.lat ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.lat}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Boylam</Label>
                              <Input
                                name="long"
                                label="long"
                                type="text"
                                placeholder="Boylam Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.long || ""}
                                invalid={
                                  validation.touched.long &&
                                    validation.errors.long
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.long &&
                                validation.errors.long ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.long}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">İban</Label>
                              <Input
                                name="iban"
                                label="iban"
                                type="iban"
                                placeholder="İban Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.iban || ""}
                                invalid={
                                  validation.touched.iban &&
                                    validation.errors.iban
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.iban &&
                                validation.errors.iban ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.iban}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Çalışan Sayısı</Label>
                              <Input
                                name="numberofemployees"
                                label="numberofemployees"
                                type="numberofemployees"
                                placeholder="Çalışan Sayısı Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.numberofemployees || ""}
                                invalid={
                                  validation.touched.numberofemployees &&
                                    validation.errors.numberofemployees
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.numberofemployees &&
                                validation.errors.numberofemployees ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.numberofemployees}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>


              
                            <Col lg={6}>

<div className="mb-3">
          <Label className="form-label">Şehir Seçiniz</Label>
          <Input
            name="selectCity"
            type="select"
            className="form-select"
            defaultValue={getCityID}
            onChange={(text) => _setChoiceCity(text)}
            value={
              getCityID
            }>
            {getListCity.map((item, index) => (
              <option value={item.iso2} key={item.iso2}>{item.name}</option>
            ))}

          </Input>
       
        </div>

      
        </Col>

       { getSelectCity != null ? 
        <Col lg={6}> 
        <div className="mb-3">
          <Label className="form-label">Semt Seçiniz</Label>
          <Input
            name="paymentStatus"
            type="select"
            className="form-select"
            defaultValue={getDistID}
            onChange={(text) => _setChoicegetDist(text)}
            value={
              getDistID
            }>
            {getListDisct.map((item, index) => (
              <option value={item.id} key={item.id}>{item.name}</option>
            ))}

          </Input>
       
        </div>


     </Col> : null}



                            <div className="mb-3">
                              <Label className="form-label">Adres </Label>
                              <Input
                                name="address"
                                label="address"
                                type="address"
                                placeholder="Adres  Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address &&
                                    validation.errors.address
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.address &&
                                validation.errors.address ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.address}
                                </FormFeedback>
                              ) : null}
                            </div>



                      





                            <Col lg={6}>
                            <div className="d-flex">
                              <label htmlFor="resume">Aktif Mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  className="switch"
                                  defaultChecked={getIsActive}
                                  onChange={() =>
                                    setIsActive(!getIsActive)
                                  }
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div>
                            </Col>



                            <Col lg={6}>
                            <div className="d-flex">
                              <label htmlFor="resume">Özel Sektör Mü?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch2"
                                  className="switch"
                                  defaultChecked={getIsPrivate}
                                  onChange={() =>
                                    setIsPrivate(!getIsPrivate)
                                  }
                                />
                                <label
                                  htmlFor="square-switch2"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div>
                            </Col>



                     






                         
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
