import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Status } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";
import Dropzone from 'react-dropzone-uploader';
import { Base64 } from 'js-base64';
import {
  getSliders as onGetSliders,
  addSlider as onAddSlider,
  updateSlider as onUpdateSlider,
  deleteSlider as onDeleteSlider,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Slider | Kupon ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const [getFileName, setFileName] = useState(null);

  const [getIsActive, setIsActive] = useState(false);
  const [getCampainData, setCampainData] = useState([]);
  const [selectedValuesCompany, setSelectedValuesCompany] = useState([]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title:(contactEdit && contactEdit.Slider_Title) || "",
      sort: (contactEdit && contactEdit.Slider_Sort) || ""
    },
    validationSchema: Yup.object({


    }),
    onSubmit: values => {
      if (isEdit) {

        selectedValuesCompany.map((item, index) => {
          const newUser = {
            ID: contactEdit.ID,
            Slider_Sort: values["sort"],
            SliderImage: getFileName,
            Slider_Title:values["title"],
            SliderActive:getIsActive,
            Campain_ID : getCampainID,
            Company_ID:item
          };
        dispatch(onUpdateSlider(newUser));
      })

        setIsEdit(false);
        validation.resetForm();
     
      } else {

        selectedValuesCompany.map((item, index) => {
        const newUser = {
          Slider_Sort: values["sort"],
          SliderImage: getFileName,
          Slider_Title:values["title"],
          SliderActive:getIsActive,
          Campain_ID : getCampainID,
          Company_ID:item
        };
       
        // save new user
        dispatch(onAddSlider(newUser));
        validation.resetForm();
        })

      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.sliders,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);


  const [getCampainID, setCampainID] = useState(null);
  const [getCompanyID, setCompanyID] = useState(null);
  const [getCategoryCompany, setCategoryCompany] = useState([]);



  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },

      {
        Header: "ID",
        accessor: "ID",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.SliderImage ? (
              <div className="avatar-xs">
                {cellProps.Slider_Sort != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Slider_Sort}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.SliderImage}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
    
      {
        Header: "Başlık",
        accessor: "Slider_Title",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


      {
        Header: "Aktiflik Durumu",
        accessor: "SliderActive",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },

      {
        Header: "Sıralama",
        accessor: "Slider_Sort",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },



      {
        Header: "İlgili Kampanya",
        accessor: "Campain.Campain_Title",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },



      {
        Header: "İlgili Firma",
        accessor: "Company.Company_Title",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },



      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

             
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getCampainData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getCampainKupon/select/`)
      .then((res) => {
        setCampainData(res.data)
      })
  } 


  const _getAuthDataCompany = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getCompanyKuponSearch/select/`)
      .then((res) => {
        setCategoryCompany(res.data)
      })
  }

  useEffect(() => {
    _getAuthDataCompany()
    _getCampainData()

    if (users && !users.length) {
      dispatch(onGetSliders());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };




  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    setSelectedValuesCompany([user.Company_ID])

    setFileName(user.SliderImage)
    setIsActive(user.SliderActive)
    setIsEdit(true);
    setCampainID(user.Campain_ID)
    setCompanyID(user.Company_ID)


    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteSlider(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);

    toggle();
  };



  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }


  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }


  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }

  const _setChoiceCampain = arg => {
    setCampainID(arg.target.value)
  }

  const handleSelectChangeCompany = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    console.log("klnsdf",selectedOptions)
    setSelectedValuesCompany(selectedOptions); // Seçilen değerleri durum olarak güncelleyin
  }

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);


  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Kullanıcının girdiği metne göre seçenekleri filtrele
    const filtered = getCategoryCompany.filter(option =>
        option.Company_Title.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredOptions(filtered);
};

  return (
    <React.Fragment>



      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Slider Listesi" />

          <Row>
          <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddSlider={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
             

                  <Modal  size="lg" isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Slider Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                        <Col lg="12">
                          <div className="mb-3">
                              <Label className="form-label">Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Başlık Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Sıralama</Label>
                              <Input
                                name="sort"
                                label="sort"
                                type="number"
                                placeholder="Sıralama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sort || ""}
                                invalid={
                                  validation.touched.sort &&
                                    validation.errors.sort
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.sort &&
                                validation.errors.sort ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sort}
                                </FormFeedback>
                              ) : null}
                            </div>

                           
                            <Col lg={6} className="mb-3">
                                <label htmlFor="resume">Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>
                           

                           {   getFileName != null ? <Col lg={6} className="mb-3">
                            <div>
                <img
                  className="avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + getFileName}
                  alt=""
                />
              </div> 
              </Col> : null}



                            <div className="mb-3">
                              <Label className="form-label">Kampanya Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCampainID}
                                onChange={(text) => _setChoiceCampain(text)}
                                value={
                                  getCampainID
                                }>
                                <option value="">Lütfen Seçim Yapınız</option>
                                {getCampainData.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Campain_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                            <Label className="form-label">Firma Seçiniz</Label>

<input
type="text"
className="form-control"
placeholder="Arama yapın..."
value={searchTerm}
onChange={handleSearchChange}
/>

<Input
  name="paymentStatus"
  type="select"
  className="form-select"
  defaultValue={selectedValuesCompany}
  multiple
  onChange={(text) => handleSelectChangeCompany(text)}
  value={
    selectedValuesCompany
  }>
  {filteredOptions.length > 0 ? filteredOptions.map((item, index) => (
<option value={item.ID} key={item.ID}>{item.Company_Title} - {item.Users.length}</option>
)) :  getCategoryCompany.map((item, index) => (
<option value={item.ID} key={item.ID}>{item.Company_Title} - {item.Users.length}</option>
))}

</Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
                            <div className="d-flex">
                          <label htmlFor="resume">Aktif Mi?</label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                         
                        </div>

                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
