import axios from "axios";
import { del, get, post, put, patch } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get Events

// add Events

// update Event


// delete Event
export const deleteEvent = project =>
  del(`https://kuponifypremium.com.tr:8099/deleteEventMega/${project}`);


// get Categories

// get chats

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages


// post messages
export const getselectedmails = (selectedmails) => post(url.GET_SELECTED_MAILS, selectedmails);

//post setfolderonmails
export const setfolderonmails = (selectedmails, folderId, activeTab) => post(url.SET_FOLDER_SELECTED_MAILS, { selectedmails, folderId, activeTab });

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get crypto product
export const getCryptoProduct = () => get(url.GET_CRYPTO_PRODUCTS);

// get invoices
export const getInvoices = () => get(url.GET_INVOICES);

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST);

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB);

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);

export const addSubEducation = user => post(`https://kuponifypremium.com.tr:8099/addCampainHistoryCompany/send`, user);
export const updateSubEducation = user => patch(`https://kuponifypremium.com.tr:8099/updateSubEducationNefes/${user.ID}`, user);
export const deleteSubEducation = user => del(`https://kuponifypremium.com.tr:8099/deleteSubEducation/${user}`);
export const addEducation = user => post(`https://kuponifypremium.com.tr:8099/addEducationNefes/send`, user);
export const addProductCategory = user => post(`https://kuponifypremium.com.tr:8099/addProductsCategoryLuxun/send`, user);
export const deleteProductCategory = user => del(`https://kuponifypremium.com.tr:8099/deleteProductCategoryLuxun/${user}`);
export const updateProductCategory = user => patch(`https://kuponifypremium.com.tr:8099/updateProductCategoryLuxun/${user.ID}`, user);
export const addBlog = user => post(`https://kuponifypremium.com.tr:8099/addBlogMSE/send`, user);
export const updateEducation = user => patch(`https://kuponifypremium.com.tr:8099/updateEducationNefes/${user.ID}`, user);

export const updateAbout = user => patch(`https://kuponifypremium.com.tr:8099/updateAboutKupon/${user.ID}`, user);
export const updateContact = user => patch(`https://kuponifypremium.com.tr:8099/updateContactPort/${user.ID}`, user);
export const getCategory = () => get(`https://kuponifypremium.com.tr:8099/getProductsCategoryLuxun/select/`);
export const getProducts1 = () => get(`https://kuponifypremium.com.tr:8099/getSmsKupon/select/`);
export const getSliders = () => get(`https://kuponifypremium.com.tr:8099/getSliderKupon/select/`);


export const getAvailable = (sd,fd) => get(`https://kuponifypremium.com.tr:8099/getCampainHistoryDate/select/${sd}/${fd}`);
export const updateAvailable = user => patch(`https://kuponifypremium.com.tr:8099/updateAvailablePortsPort/${user.ID}`, user);
export const addAvailable = job => post(`https://kuponifypremium.com.tr:8099/addAvailablePortsPort/send`, job);
export const deleteAvailable = project =>
del(`https://kuponifypremium.com.tr:8099/deleteAvailablePortsPort/${project}`);



export const getCampainCategory = () => get(`https://kuponifypremium.com.tr:8099/getCampainCategoryKupon/select/`);
export const updateCampainCategory = user => patch(`https://kuponifypremium.com.tr:8099/updateCampainCategoryKupon/${user.ID}`, user);
export const addCampainCategory = job => post(`https://kuponifypremium.com.tr:8099/addCampainCategoryKupon/send`, job);
export const deleteCampainCategory= project =>
del(`https://kuponifypremium.com.tr:8099/deleteCampainCategoryKupon/${project}`);



export const getCampain = () => get(`https://kuponifypremium.com.tr:8099/getCampainKupon/select/`);
export const updateCampain= user => patch(`https://kuponifypremium.com.tr:8099/updateCampainKupon/${user.ID}`, user);
export const addCampain = job => post(`https://kuponifypremium.com.tr:8099/addCampainKupon/send`, job);

export const deleteCampain= project =>
del(`https://kuponifypremium.com.tr:8099/deleteCampainKupon/${project}`);


export const getEducationSubItem = user => get(`https://kuponifypremium.com.tr:8099/getCampainHistoryKuponFilter/select/${user}`);

export const getCampainHistory = () => get(`https://kuponifypremium.com.tr:8099/getCampain_HistoryKupon/select/`);
export const updateCampainHistory= user => patch(`https://kuponifypremium.com.tr:8099/updateCampain_HistoryKupon/${user.ID}`, user);
export const addCampainHistory = job => post(`https://kuponifypremium.com.tr:8099/addCampain_HistoryKupon/send`, job);

export const deleteCampainHistory= project =>
del(`https://kuponifypremium.com.tr:8099/deleteCampain_HistoryKupon/${project}`);


export const getJob = () => get(`https://kuponifypremium.com.tr:8099/getJobKupon/select/`);
export const updateJob= user => patch(`https://kuponifypremium.com.tr:8099/updateJobKupon/${user.ID}`, user);
export const addJob = job => post(`https://kuponifypremium.com.tr:8099/addJobKupon/send`, job);
export const deleteJob= project =>
del(`https://kuponifypremium.com.tr:8099/deleteJobKupon/${project}`);

export const getSector = () => get(`https://kuponifypremium.com.tr:8099/getSectorKupon/select/`);
export const updateSector= user => patch(`https://kuponifypremium.com.tr:8099/updateSectorKupon/${user.ID}`, user);
export const deleteSector= project =>
del(`https://kuponifypremium.com.tr:8099/deleteSectorKupon/${project}`);

export const addSector = job => post(`https://kuponifypremium.com.tr:8099/pushnotificationKupon/send`, job);


export const getCampainPeriod = () => get(`https://kuponifypremium.com.tr:8099/getCampaign_PeriodKupon/select/`);
export const updateCampainPeriod= user => patch(`https://kuponifypremium.com.tr:8099/updateCampaign_PeriodKupon/${user.ID}`, user);
export const addCampainPeriod= job => post(`https://kuponifypremium.com.tr:8099/Campaign_PeriodKupon/send`, job);
export const deleteCampainPeriod= project =>
del(`https://kuponifypremium.com.tr:8099/Campaign_PeriodKupon/${project}`);


export const getSubSector = () => get(`https://kuponifypremium.com.tr:8099/getSubSectorKupon/select/`);
export const updateSubSector= user => patch(`https://kuponifypremium.com.tr:8099/updateSubSectorKupon/${user.ID}`, user);
export const addSubSector = job => post(`https://kuponifypremium.com.tr:8099/addSubSectorKupon/send`, job);
export const deleteSubSector= project =>
del(`https://kuponifypremium.com.tr:8099/deleteSubSectorKupon/${project}`);

export const getCompany = () => get(`https://kuponifypremium.com.tr:8099/getCompanyKupon/select/`);
export const updateCompany = user => patch(`https://kuponifypremium.com.tr:8099/updateCompanyKupon/${user.ID}`, user);
export const addCompany= job => post(`https://kuponifypremium.com.tr:8099/addCompanyKupon/send`, job);
export const deleteCompany= project =>
del(`https://kuponifypremium.com.tr:8099/deleteCompanyKupon/${project}`);



export const getFeedBack = () => get(`https://kuponifypremium.com.tr:8099/getFeedBackKupon/select/`);
export const updateFeedBack = user => patch(`https://kuponifypremium.com.tr:8099/updateFeedBackKupon/${user.ID}`, user);
export const addFeedBack= job => post(`https://kuponifypremium.com.tr:8099/addFeedBackKupon/send`, job);
export const deleteFeedBack= project =>
del(`https://kuponifypremium.com.tr:8099/deleteFeedBackKupon/${project}`);


export const getBlogCat = () => get(`https://kuponifypremium.com.tr:8099/getCampainCompany/select/`);
export const updateBlogCat = user => patch(`https://kuponifypremium.com.tr:8099/updateBlogCategoryMSE/${user.ID}`, user);
export const addBlogCat = job => post(`https://kuponifypremium.com.tr:8099/addBlogCategoryMSE/send`, job);
export const deleteBlogCat= project =>
del(`https://kuponifypremium.com.tr:8099/deleteBlogCategoryMSE/${project}`);


export const getBlogSubCat = () => get(`https://kuponifypremium.com.tr:8099/getSubCategoryMSE/select/`);
export const updateBlogSubCat = user => patch(`https://kuponifypremium.com.tr:8099/updateSubCategoryMSE/${user.ID}`, user);
export const addBlogSubCat = job => post(`https://kuponifypremium.com.tr:8099/addSubCategoryMSE/send`, job);
export const deleteBlogSubCat= project =>
del(`https://kuponifypremium.com.tr:8099/deleteBlogSubCategoryMSE/${project}`);


export const getFeedBackCategory = () => get(`https://kuponifypremium.com.tr:8099/getFeedBackCategoryKupon/select/`);

export const getCompanyReferanceCode = () => get(`https://kuponifypremium.com.tr:8099/getCompanyReferanceCode/select/`);
export const updateCompanyReferanceCode = user => patch(`https://kuponifypremium.com.tr:8099/updateCompanyReferanceCode/${user.ID}`, user);
export const updateFeedbackCategory = user => patch(`https://kuponifypremium.com.tr:8099/updateFeedbackCategory/${user.ID}`, user);
export const addFeedBackCategory= job => post(`https://kuponifypremium.com.tr:8099/addFeedBackCategory/send`, job);
export const deleteFeedBackCategory= project =>
del(`https://kuponifypremium.com.tr:8099/deleteFeedBackCategory/${project}`);

export const addCompanyReferanceCode= job => post(`https://kuponifypremium.com.tr:8099/addCompanyReferanceCode/send`, job);
export const deleteCompanyReferanceCode= project =>
del(`https://kuponifypremium.com.tr:8099/deleteCompanyReferanceCode/${project}`);

export const deleteAuth= project =>
del(`https://kuponifypremium.com.tr:8099/deleteAuthKupon/${project}`);

export const getAdvert = () => get(`https://kuponifypremium.com.tr:8099/getAdvertTekneport/select/`);
export const updateAdvert = user => patch(`https://kuponifypremium.com.tr:8099/updateTekneportAdvert/${user.ID}`, user);
export const addAdvert= job => post(`https://kuponifypremium.com.tr:8099/addAdvertTekneport/send`, job);
export const deleteAdvert= project =>
del(`https://kuponifypremium.com.tr:8099/deleteAdvertPortsPort/${project}`);



export const getReservation = () => get(`https://kuponifypremium.com.tr:8099/getReservationPort/select/`);
export const updateReservation = user => patch(`https://kuponifypremium.com.tr:8099/updateSliderPort/${user.ID}`, user);
export const addReservation = job => post(`https://kuponifypremium.com.tr:8099/addSliderPort/send`, job);
export const deleteReservation = project =>
del(`https://kuponifypremium.com.tr:8099/deleteSSSPort/${project}`);


export const getOffer = () => get(`https://kuponifypremium.com.tr:8099/getOfferPort/select/`);
export const updateOffer = user => patch(`https://kuponifypremium.com.tr:8099/updateSliderPort/${user.ID}`, user);
export const addOffer = job => post(`https://kuponifypremium.com.tr:8099/addSliderPort/send`, job);
export const deleteOffer = project =>
del(`https://kuponifypremium.com.tr:8099/deleteSSSPort/${project}`);



export const getSSS = () => get(`https://kuponifypremium.com.tr:8099/getSSSKupon/select/`);
export const getBlogs = () => get(`https://kuponifypremium.com.tr:8099/getBlogMSE/select/`);
export const getUsers = () => get(`https://kuponifypremium.com.tr:8099/getUsersKupon/select/`);
export const deleteUser = user => del(`https://kuponifypremium.com.tr:8099/deleteUsersKupon/${user}`);
export const getProjectsNew = () => get(`https://kuponifypremium.com.tr:8099/getEducationNefes/select/`);
export const getEducation = () => get(`https://kuponifypremium.com.tr:8099/getEducationNefes/select/`);

export const getPopup = user => get(`https://kuponifypremium.com.tr:8099/getUserCompanyKupon/select/${user}`);

export const getWorkShop = () => get(`https://kuponifypremium.com.tr:8099/getSUBDOMAINMega/select/`);

export const getCourseSchedulesMega = () => get(`https://kuponifypremium.com.tr:8099/getCourseSchedulesMega/select/`);

export const getWorkshopSessionMega = () => get(`https://kuponifypremium.com.tr:8099/getWorkshopSessionMega/select/`);

export const getTeacherMega = () => get(`https://kuponifypremium.com.tr:8099/getNotificationKupon/select/`);

export const getEventMega = () => get(`https://kuponifypremium.com.tr:8099/getEventMega/select/`);

export const getDynamicFormMega = () => get(`https://kuponifypremium.com.tr:8099/getCompanyReferanceCode/select/`);

export const addDynamicForm = job => post(`https://kuponifypremium.com.tr:8099/addCompanyReferanceCode/send`, job);

export const updateDynamicForm = user => patch(`https://kuponifypremium.com.tr:8099/updateCompanyReferanceCode/${user.ID}`, user);

export const deleteDynamicForm = project =>
  del(`https://kuponifypremium.com.tr:8099/deleteCompanyReferanceCode/${project}`);

export const getSlider = () => get(`https://kuponifypremium.com.tr:8099/getSliderKupon/select/`);


export const getNews = () => get(`https://kuponifypremium.com.tr:8099/getNewsMega/select/`);

export const getMegaMega = () => get(`https://kuponifypremium.com.tr:8099/getMegaMega/select/`);

export const getMailsLists = () => get(`https://kuponifypremium.com.tr:8099/getContactMessageMega/select/`);

// add user

const postFakeLogin = data => get(`https://kuponifypremium.com.tr:8099/getUserLoginKupon/select/${data.email}/${data.password}`,);

export const getEvents = () => get(`https://kuponifypremium.com.tr:8099/getEventMega/select/`);
export const getAbout = () => get(`https://kuponifypremium.com.tr:8099/getAboutKupon/select/`);
export const getCategories = () => get(`https://kuponifypremium.com.tr:8099/getEventCategoryMega/select/`);
// update user

export const updateSubDomain = user => patch(`https://kuponifypremium.com.tr:8099/patchSUBDOMAINMega/${user.ID}`, user);

export const updateCourseSchedules = user => patch(`https://kuponifypremium.com.tr:8099/patchCourseSchedulesMega/${user.ID}`, user);

export const updateWorkshopSession = user => patch(`https://kuponifypremium.com.tr:8099/patchWorkShopSessionMega/${user.ID}`, user);

export const updateTeacher = user => patch(`https://kuponifypremium.com.tr:8099/patchUserMega/${user.ID}`, user);

export const updateSlider = user => patch(`https://kuponifypremium.com.tr:8099/updateSliderKupon/${user.ID}`, user);

export const updateSSS = user => patch(`https://kuponifypremium.com.tr:8099/updateSSSKupon/${user.ID}`, user);

export const updateProduct = user => patch(`https://kuponifypremium.com.tr:8099/updateProductLuxun/${user.ID}`, user);
export const updateBlog = user => patch(`https://kuponifypremium.com.tr:8099/updateBlogMSE/${user.ID}`, user);
export const updateUser = user => patch(`https://kuponifypremium.com.tr:8099/updateUsersKupon/${user.ID}`, user);

export const updatePopup = user => patch(`https://kuponifypremium.com.tr:8099/updateUsersKupon/${user.ID}`, user);

export const addPopup = job => post(`https://kuponifypremium.com.tr:8099/addUsersKupon/send`, job);

export const deletePopup = project =>
  del(`https://kuponifypremium.com.tr:8099/deleteUsersKupon/${project}`);


export const updateProject = user => patch(`https://kuponifypremium.com.tr:8099/patchUpdateProject/${user.ID}`, user);

export const updateNews = user => patch(`https://kuponifypremium.com.tr:8099/patchNews/${user.ID}`, user);


export const addSlider = job => post(`https://kuponifypremium.com.tr:8099/addSliderKupon/send`, job);

export const addSSS= job => post(`https://kuponifypremium.com.tr:8099/addSSSKupon/send`, job);
export const addProduct = job => post(`https://kuponifypremium.com.tr:8099/addProductLuxun/send`, job);
export const addUser = job => post(`https://kuponifypremium.com.tr:8099/addUsersKupon/insert`, job);
export const addNewUser = user => post(`https://kuponifypremium.com.tr:8099/addGroupTypeKupon/send`, user);


export const addProject = job => post(`https://kuponifypremium.com.tr:8099/addProject/send`, job);

export const addNews = job => post(`https://kuponifypremium.com.tr:8099/addNews/send`, job);

export const addMega = job => post(`https://kuponifypremium.com.tr:8099/addMega/send`, job);

export const addCourseMega = job => post(`https://kuponifypremium.com.tr:8099/addCourseMega/send`, job);

export const updateSubdomain = user => patch(`https://kuponifypremium.com.tr:8099/patchSUBDOMAINMega/${user.ID}`, user);




export const updateMega = user => patch(`https://kuponifypremium.com.tr:8099/patchMegaMega/${user.ID}`, user);

export const updateGallery = user => patch(`https://kuponifypremium.com.tr:8099/patchGalleryMega/${user.ID}`, user);

export const updateEvent = event => patch(`https://kuponifypremium.com.tr:8099/patchEventMega/${event.ID}`, event);

export const addAppSetting = job => post(`https://kuponifypremium.com.tr:8099/postWSMega/send`, job);

export const addWorkshop2 = job => post(`https://kuponifypremium.com.tr:8099/postWorkshopMega/send`, job);



// delete user

export const getContact = () => get(`https://kuponifypremium.com.tr:8099/getContactPort/select/`);
export const getWorkshop2 = () => get(`https://kuponifypremium.com.tr:8099/getWorkshopMega/select/`);

export const updateWorkShop2 = user => patch(`https://kuponifypremium.com.tr:8099/patchWorkshop2Mega/${user.ID}`, user);

export const getGallery = () => get(`https://kuponifypremium.com.tr:8099/getGalleryMega/select/`);

export const addNewEvent = event => post(`https://kuponifypremium.com.tr:8099/postEventMega/send/`, event);

export const getChats = () => get(`https://kuponifypremium.com.tr:8099/getContactMessageMega/select/`);

export const getMessages = (roomId = "") => get(`https://kuponifypremium.com.tr:8099/getContactMessageDetailMega/select/${roomId}`);

export const addNewSubdomain = event => post(`https://kuponifypremium.com.tr:8099/postSubdomainMega/send/`, event);
export const getReferances = () => get(`https://kuponifypremium.com.tr:8099/getReferancesMega/select/`);
// add jobs
export const addNewJobList = job => post(url.ADD_NEW_JOB_LIST, job);
// update jobs
export const updateJobList = job => put(url.UPDATE_JOB_LIST, job);
// delete jobs
export const deleteJobList = job => del(url.DELETE_JOB_LIST, { headers: { job } });
// Delete Apply Jobs
export const deleteApplyJob = data => del(url.DELETE_APPLY_JOB, { headers: { data } });
/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);


export const addReferances = event => post(`https://kuponifypremium.com.tr:8099/postReferancesMega/send/`, event);

export const updateReferances = user => patch(`https://kuponifypremium.com.tr:8099/patchReferancesMega/${user.ID}`, user);

export const getSubProject = (ID = "") => get(`https://kuponifypremium.com.tr:8099/getProjectSubItem/select/${ID}`);

export const getTeacherWorkshop = () => get(`https://kuponifypremium.com.tr:8099/getTeacherWorkshopMega/select/`);

export const addLessonsTime = event => post(`https://kuponifypremium.com.tr:8099/postLessonsTimeMega/send/`, event);

export const updateLessonsTime = user => patch(`https://kuponifypremium.com.tr:8099/patchTeacherWorkshopMega/${user.ID}`, user);

export const getClassAttendance = () => get(`https://kuponifypremium.com.tr:8099/getClassAttendanceMega/select/`);

export const getClassesMega = () => get(`https://kuponifypremium.com.tr:8099/getClassesMega/select/`);


export const getDynamicFormExtraMega = (ID = "") => get(`https://kuponifypremium.com.tr:8099/getDynamicFormExtraMega/select/${ID}`);

export const getDynamicFormExtraExtraMega = (ID = "") => get(`https://kuponifypremium.com.tr:8099/getDynamicFormExtraExtraMega/select/${ID}`);


export const addGallerry = job => post(`https://kuponifypremium.com.tr:8099/postGalleryMega/send`, job);

export const addMultipleGalleryForm = job => post(`https://kuponifypremium.com.tr:8099/postGalleryMultipleMega/send`, job);


export const deleteDynamicFormExtraExtra = project =>
  del(`https://kuponifypremium.com.tr:8099/deleteDynamicExtraExtraFormMega/${project}`);


export const deleteDynamicFormExtra = project =>
  del(`https://kuponifypremium.com.tr:8099/deleteDynamicFormExtraMega/${project}`);

export const deleteCourseSchedules = project =>
  del(`https://kuponifypremium.com.tr:8099/deleteCourseSchedulesMega/${project}`);

export const deleteGallery = project =>
  del(`https://kuponifypremium.com.tr:8099/deleteGalleryMega/${project}`);

export const deleteReferances = project =>
  del(`https://kuponifypremium.com.tr:8099/deleteReferancesMega/${project}`);


export const deleteSlider = project =>
  del(`https://kuponifypremium.com.tr:8099/deleteSliderKupon/${project}`);
  
  export const getAuthority = () => get(`https://kuponifypremium.com.tr:8099/getGroupTypeKupon/select/`);
  export const updateAuth = user => patch(`https://kuponifypremium.com.tr:8099/updateGroupTypeKupon/${user.ID}`, user);

  
  export const deleteSSS = project =>
  del(`https://kuponifypremium.com.tr:8099/deleteSSSKupon/${project}`);
  


export const deleteProduct = project =>
del(`https://kuponifypremium.com.tr:8099/deleteProductLuxun/${project}`);

  export const deleteBlog = project =>
  del(`https://kuponifypremium.com.tr:8099/deleteBlogMSE/${project}`);


  

export const deleteNews = project =>
  del(`https://kuponifypremium.com.tr:8099/deleteNewsMega/${project}`);

export const deleteMega = project =>
  del(`https://kuponifypremium.com.tr:8099/deleteMega/${project}`);

export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

//update mail
export const updateMail = mail => put(url.UPDATE_MAIL, mail);

// get folderlist
export const selectFolders = () => get(url.SELECT_FOLDER);

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const walletBalanceData = month =>
  get(`${url.GET_WALLET_DATA}/${month}`, { params: { month } });

export const getStatisticData = duration =>
  get(`${url.GET_STATISTICS_DATA}/${duration}`, { params: { duration } });

export const visitorData = duration =>
  get(`${url.GET_VISITOR_DATA}/${duration}`, { params: { duration } });

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS);

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  });
};
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  });
};

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  });
};

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
};