import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateAbout as onUpdateAbout,
  getAbout as onGetAbout,
} from "store/contacts/actions";
import toastr from 'toastr'; // toastr kütüphanesini içe aktarın
import 'toastr/build/toastr.min.css'; // toastr stillerini içe aktarın

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dropzone from 'react-dropzone-uploader';
import { Base64 } from 'js-base64';

const EcommerceAddProduct = () => {
  document.title = "Hakkımızda Bilgileri | Kupon Admin Paneli";

  const dispatch = useDispatch();

  const [getContactEdit, setContactEdit] = useState(null);
  const [getHtmlDataLong, setHtmlDataLong] = useState(null);
  const [getHtmlDataLongSecond, setHtmlDataLongSecond] = useState(null);
  const [getFileName, setFileName] = useState('');
  const [getHtmlDataKVKK, setHtmlDataKvkk] = useState(null);

  

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const showToast= async (type) => {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla güncellendi."
    } else{
       ele = "error"
       message = "type"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      mail: (getContactEdit && getContactEdit.About_Mail) || '',
      number: (getContactEdit && getContactEdit.About_Number) || '',
      web: (getContactEdit && getContactEdit.WebAdress) || '',
      address: (getContactEdit && getContactEdit.AboutAdress) || '',

      lat: (getContactEdit && getContactEdit.Lat) || '',
      long: (getContactEdit && getContactEdit.Long) || '',

    },
    validationSchema: Yup.object({
      mail: Yup.string().required("Lütfen Mail Giriniz")
    }),
    onSubmit: (values) => {

      const updateOrder = {
        ID: users ? users[0].ID : 0,
        About_Desc: Base64.encode(getHtmlDataLong),
        TermsofUse: Base64.encode(getHtmlDataLongSecond),
        About_Mail: values.mail,
        About_Number: values.number.toString(),
        WebAdress: values.web,
        AboutAdress: values.address,
        Logo:getFileName,
        Lat: values.lat,
        Long: values.long,
        About_KVKK:Base64.encode(getHtmlDataKVKK)
      };
     

      dispatch(onUpdateAbout(updateOrder));
      showToast(true)

      dispatch(onGetAbout());

     // validation.resetForm();

    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.about,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetAbout());

    }
  }, [dispatch, users]);

  useEffect(() => {
    console.log("nsdlf", users)
    if (users.length > 0) {
      setContactEdit(users[0]);
      setFileName(users[0].Logo);
      setHtmlDataLong(Base64.decode(users[0].About_Desc));
      setHtmlDataLongSecond(Base64.decode(users[0].TermsofUse));

      if(users[0].About_KVKK != null){
        setHtmlDataKvkk(Base64.decode(users[0].About_KVKK))
      }
    }
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);



  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Hakkımızda Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Hakkımızda Bilgileri</CardTitle>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>

                      <div className="mb-3">
                        <Label htmlFor="productname">Mail Adresi</Label>
                        <Input
                          id="mail"
                          name="mail"
                          type="mail"
                          className="form-control"
                          placeholder="Mail Adresi"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mail || ""}
                          invalid={
                            validation.touched.mail && validation.errors.mail ? true : false
                          }
                        />
                        {validation.touched.mail && validation.errors.mail ? (
                          <FormFeedback type="invalid">{validation.errors.mail}</FormFeedback>
                        ) : null}

                      </div>


                      <div className="mb-3">
                        <Label htmlFor="productname">Enlem</Label>
                        <Input
                          id="lat"
                          name="lat"
                          type="text"
                          className="form-control"
                          placeholder="Enlem"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lat || ""}
                          invalid={
                            validation.touched.lat && validation.errors.lat ? true : false
                          }
                        />
                        {validation.touched.lat && validation.errors.lat ? (
                          <FormFeedback type="invalid">{validation.errors.lat}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="productname">Boylam</Label>
                        <Input
                          id="long"
                          name="long"
                          type="text"
                          className="form-control"
                          placeholder="Boylam"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.long || ""}
                          invalid={
                            validation.touched.long && validation.errors.long ? true : false
                          }
                        />
                        {validation.touched.long && validation.errors.long ? (
                          <FormFeedback type="invalid">{validation.errors.long}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="productname"> Adres </Label>
                        <Input
                          id="address"
                          name="address"
                          type="address"
                          className="form-control"
                          placeholder=" Adres"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address || ""}
                          invalid={
                            validation.touched.address && validation.errors.address ? true : false
                          }
                        />
                        {validation.touched.address && validation.errors.address ? (
                          <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                        ) : null}

                      </div>



                      <div className="mb-3">
                        <Label htmlFor="productname">Web Adresi </Label>
                        <Input
                          id="web"
                          name="web"
                          type="web"
                          className="form-control"
                          placeholder="Web Adresi"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.web || ""}
                          invalid={
                            validation.touched.web && validation.errors.web ? true : false
                          }
                        />
                        {validation.touched.web && validation.errors.web ? (
                          <FormFeedback type="invalid">{validation.errors.web}</FormFeedback>
                        ) : null}

                      </div>




                      <div className="mb-3">
                        <Label htmlFor="productname">Telefon Numarası </Label>
                        <Input
                          id="number"
                          name="number"
                          type="tel"
                          className="form-control"
                          placeholder="Tel Numarası"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.number || ""}
                          invalid={
                            validation.touched.number && validation.errors.number ? true : false
                          }
                        />
                        {validation.touched.number && validation.errors.number ? (
                          <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
                        ) : null}

                      </div>


                      <div className="mb-3">
                        <Label className="form-label">Uzun Açıklama</Label>

                        <Form method="post">
                          <CKEditor
                            editor={ClassicEditor}
                            data={getHtmlDataLong}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataLong(data)

                            }}
                          />
                        </Form>
                      </div>


                      <div className="mb-3">
                        <Label className="form-label">KVKK Koşulları</Label>

                        <Form method="post">
                          <CKEditor
                            editor={ClassicEditor}
                            data={getHtmlDataKVKK}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataKvkk(data)

                            }}
                          />
                        </Form>
                      </div>


                      <div className="mb-3">
                        <Label className="form-label">Açık Rıza Metni</Label>

                        <Form method="post">
                          <CKEditor
                            editor={ClassicEditor}
                            data={getHtmlDataLongSecond}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataLongSecond(data)

                            }}
                          />
                        </Form>
                      </div>

                      <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>

                              <div>
                <img
                  className="avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' +  getFileName}
                  alt=""
                />
              </div>

                            </div>

                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Kaydet
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
