import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Date, NameSurname, Price, Status } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";
import moment from "moment";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  getSubEducation as onGetSubEducation,
  addSubEducation as onAddSubEducation,
  updateSubEducation as onUpdateSubEducation,
  deleteSubEducation as onDeleteSubEducation,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Kod | Kupon ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      price: (contactEdit && contactEdit.Price) || "",
      code: null
    },
    validationSchema: Yup.object({
   
    }),
    onSubmit: values => {

      
      if (isEdit) {

       let getData = localStorage.getItem("authUser")
        
    
          const newUser = {
            Campain_ID: getCampainCode.ID,
            Company_ID: parseInt(JSON.parse(getData).Company_ID),
            Created_DateTime: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
            Update_DateTime: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
            Price: values.price,
            Code:values.code
          };

          axios.post('https://getjob.stechomeyazilim.info:5101/addCampainHistoryCompany/send', newUser)

          dispatch(onGetSubEducation(parseInt(JSON.parse(getData).Company_ID)))
    
        setIsEdit(false);
        validation.resetForm();
      } else {

        let getData = localStorage.getItem("authUser")


        console.log("lnsdkclf",getCampainCode)
        console.log("getCodeCheck123",getCodeCheck)

        const newUser = {
          Campain_ID: getCodeCheck == "campain" ?  getCampainCode.Campain_ID : getCampainCode.Campain.ID,
          Company_ID: parseInt(JSON.parse(getData).Company_ID),
          Created_DateTime: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
          Price: values["price"],
          Code:getCodeCheck == "campain" ?  getCampainCode.Campain.Campain_Code : getCodeCheck == "history" ? getCampainCode.Campain_Code : null
        };

        
        // save new user
        dispatch(onAddSubEducation(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.educationSubItem,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [getCheckCampainCode, setCheckCampainCode] = useState(false);
  const [getCampainName, setCampainName] = useState(null);

  const [isEdit, setIsEdit] = useState(false);


  const [getCategory, setCategory] = useState([]);

  const [getCampainCode, setCampainCode] = useState(null);
  const [getCodeCheck, setCodeCheck] = useState(null);

  

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },

      {
        Header: "Kam. Adı",
        accessor: "Campain.Campain_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


      {
        Header: "Fiyat",
        accessor: "Price",
        filterable: true,
        Cell: cellProps => {
          return <Price {...cellProps} />;
        },
      },

      {
        Header: "Oluşturulma Tarihi",
        accessor: "Created_DateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },

      
      {
        Header: "KOD",
        accessor: "Code",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersKupon/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }



  useEffect(() => {
    if (users && !users.length) {
      let getData = localStorage.getItem("authUser")

      dispatch(onGetSubEducation(parseInt(JSON.parse(getData).Company_ID)));
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
      _getAuthData()
    }
  }, [users]);

  const toggle = () => {
    setCheckCampainCode(false)
    setCampainName(null)

    setModal(!modal);
  };

  const handleUserCheckCode = async(validation) => {
    const code = validation.values.code; // validation nesnesinden price'ı çekiyoruz

    let getData = localStorage.getItem("authUser")
    
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getCampainCodeCheck/select/${code}/${parseInt(JSON.parse(getData).Company_ID)}`)
    .then(async(res) => {

      
      if(res.data.length>0){
        showToast(true)

        setCampainName(res.data[0].Campain_Title)
        setCheckCampainCode(true)
        setCampainCode(res.data[0])
        setCodeCheck('campain')

      }else{
        //showToast(false)

        await axios.get(`https://getjob.stechomeyazilim.info:5101/getCampainHistorySearch/${code}`)
        .then((res) => {
    
          
          if(res.data.length>0){
            showToast(true)
    
            setCampainName(res.data[0].Campain.Campain_Title)
            setCheckCampainCode(true)
            setCampainCode(res.data[0])
            setCodeCheck('history')
           
          }else{
            showToast(false)
    
          }
        })
      }
    })

  }

  
  function showToast(type) {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Kampanya Kodu Bulundu"
    } else{
       ele = "error"
       message = "Kampanya Kodu Bulunamadı!"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteSubEducation(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Kod Onay" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddEducationSubList={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

            
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kod Onay Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                        {getCheckCampainCode == false ?     
                        <Col xs={12}>
                          <div className="mb-3">
                              <Label className="form-label">Kod</Label>
                              <Input
                                name="code"
                                label="code"
                                type="text"
                                placeholder="Kodu Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.code || ""}
                                invalid={
                                  validation.touched.code &&
                                    validation.errors.code
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.code &&
                                validation.errors.code ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.code}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <Col>
                            <div className="text-end">
                              <button
                                onClick={() => {
                                
                                  handleUserCheckCode(validation);
                                }}
                                type="button"
                                className="btn btn-danger save-user"
                              >
                                Sorgula
                              </button>
                            </div>
                          </Col>



                        {/*<div className="mb-3">
                              <Label className="form-label">Kampanya Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                {getCategory2.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Campain_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
 */} 
 

                         


                       {/*     <div className="mb-3">
                        <Label className="form-label">Kullanıcı Seçiniz</Label>
                        <Input
                          name="paymentStatus"
                          type="select"
                          className="form-select"
                          defaultValue={getCategoryID}
                          onChange={(text) => _setChoiceCategory(text)}
                          value={getCategoryID} // Change this line
                        >

                          {getCategory.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.NameSurname}</option>
                          ))}

                        </Input>
                        {validation.touched.wsText && validation.errors.wsText ? (
                          <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                        ) : null}
                      </div>
                      */} 

                        

                          </Col> : null }
                        </Row>
                        <Row>



                       {getCheckCampainCode == true ? 
                       <Col>

<Label className="form-label">{getCampainName} Kampanyası</Label>

<div className="mb-3">
                              <Label className="form-label">Fiyat</Label>
                              <Input
                                name="price"
                                label="price"
                                type="number"
                                placeholder="Fiyat Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.price || ""}
                                invalid={
                                  validation.touched.price &&
                                    validation.errors.price
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.price &&
                                validation.errors.price ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.price}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col> : null}
                        </Row>
                      </Form>
                    </ModalBody> 
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
