import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import DatePicker from "react-datepicker";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname,Date, Status } from "./contactlistCol";

import moment from "moment";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";


import Dropzone from 'react-dropzone-uploader';


import {
  getCampainPeriod as onGetCampainPeriod,
  addCampainPeriod as onAddCampainPeriod,
  updateCampainPeriod as onUpdateCampainPeriod,
  deleteCampainPeriod as onDeleteCampainPeriod,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Kampanya Dönemi  | KUPON ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const [getFileSubMedia, setFileSubMedia] = useState([]);
  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);

  const [getSubMediaID, setSubMediaID] = useState();

  const [getIsActive, setIsActive] = useState(false);
  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Campaign_Period_Name) || "",
      desc: (contactEdit && contactEdit.Campaign_Period_Desc) || "",
      startDate: (contactEdit && moment(contactEdit.StartDate).format('YYYY-MM-DD')) || "",
      finishDate: (contactEdit && moment(contactEdit.FinishDate).format('YYYY-MM-DD')) || "",

    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),


    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Campaign_Period_Name: values.title,
          Campaign_Period_Desc: values.desc,
          IsActive: getIsActive,
          StartDate: moment(values.startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
          FinishDate:moment(values.finishDate).format('YYYY-MM-DDTHH:mm:ssZ'),
        };
        dispatch(onUpdateCampainPeriod(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Campaign_Period_Name: values["title"],
          Campaign_Period_Desc: values["desc"],
          IsActive: getIsActive,
          StartDate: moment(values.startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
          FinishDate:moment(values.finishDate).format('YYYY-MM-DDTHH:mm:ssZ'),

        };
        // save new user
        dispatch(onAddCampainPeriod(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.campainperiod,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);


  const [getCategoryID, setCategoryID] = useState(null);


  const [getCategory, setCategory] = useState([]);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },

      {
        Header: "ID",
        accessor: "ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },



      {
        Header: "Kampanya Dönemi Başlık",
        accessor: "Campaign_Period_Name",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },



      {
        Header: "Baş. Tarihi",
        accessor: "StartDate",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },



      {
        Header: "Bit. Tarihi",
        accessor: "FinishDate",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },

      {
        Header: "Aktif mi?",
        accessor: "IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },



      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">



              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductsCategoryLuxun/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()
    if (users && !users.length) {
      dispatch(onGetCampainPeriod());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };


  const handleUserClickMedia = (arg) => {
    const user = arg;

    setSubMediaID(user.ID)

    setFileSubMedia(user.Products_Image)

    toggleMedia();
  };


  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);


      setIsActive(user.IsActive)


    setIsEdit(true);


    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteCampainPeriod(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const _deleteMedia = async (itemData) => {

    try {

      axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteProductImageLuxun/${itemData.ID}`).then(async (res) => {

        await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductsMediaLuxun/select/${getSubMediaID}`)
          .then((res) => {
            setFileSubMedia(res.data)
          })


      })
    } catch (error) {
      //yield put(updateUserFail(error))
    }


    /*  const filteredData = getFileSubMediaAdd.filter(item => item.ID !== itemData.ID )
      setFileSubMediaAdd(filteredData);*/

  }

  const _addMedia = async () => {


    getFileSubMediaAdd.map(async (item, index) => {
      const newUser = {
        Products_ID: getSubMediaID,
        //EducationSubItem_Media_Type: item.fileType,
        Url: item.fileName
      }

      axios.post('https://getjob.stechomeyazilim.info:5101/addProductsMediaLuxun/send', newUser)

      await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductsMediaLuxun/select/${getSubMediaID}`)
        .then((res) => {
          setFileSubMedia(res.data)
        })

      dispatch(onGetProduct());

      toggleMedia();
    })
  }

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }

  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response)
          setFileSubMediaAdd(oldArray => [{ fileName: result.file.filename, fileType: result.file.mimetype }, ...oldArray]);
        }
      }
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Kampanya Dönemi Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddCampainPeriod={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modalMedia} toggle={toggleMedia}>
                    <ModalHeader toggle={toggleMedia} tag="h4">
                      {!!isEdit ? "Düzenle" : "Medya Ekle"}
                    </ModalHeader>
                    <ModalBody>

                      <Row>
                        <Col xs={12}>


                          <div className="mb-3">
                            <label htmlFor="resume">Görsel</label>
                            <Dropzone
                              maxFiles={5}
                              getUploadParams={getUploadParams}
                              multiple={true}
                              onChangeStatus={handleChangeStatusBanner}
                              styles={{
                                dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                inputLabelWithFiles: { margin: '20px 3%' }
                              }}
                              canRemove={true}
                              PreviewComponent={Preview}
                              accept="image/*"
                            />
                          </div>

                        </Col>
                      </Row>
                      <Row>

                        {getFileSubMedia.map((item, index) => (
                          <div key={index}>
                            <img src={'https://megasubdomain.stechomeyazilim.info/' + item.Url} alt="" className="avatar-sm" />

                            <button
                              onClick={() => _deleteMedia(item)}
                              type="submit"
                              className="btn btn-danger save-user"
                            >
                              Sil
                            </button>

                          </div>
                        ))}



                        <Col>
                          <div className="text-end">
                            <button
                              onClick={() => _addMedia()}
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Medyaları Kaydet
                            </button>
                          </div>
                        </Col>
                      </Row>

                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kampanya Dönemi   Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>



                            <div className="mb-3">
                              <Label className="form-label">Kampanya Dönemi Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Kampanya Dönemi Başlık Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>



                          </Col>
                        </Row>

                      


                        <Row>
                          <Col xs={12}>



                            <div className="mb-3">
                              <Label className="form-label">Kampanya Dönemi Açıklama</Label>
                              <Input
                                name="desc"
                                label="desc"
                                type="text"
                                placeholder="Kampanya Dönemi Açıklama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.desc || ""}
                                invalid={
                                  validation.touched.desc &&
                                    validation.errors.desc
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.desc &&
                                validation.errors.desc ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.desc}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                      <Label className="form-label">Başlangıç Tarihi</Label>
                      <Input
                        name="startDate"
                        type="date"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.startDate || ""}
                        invalid={
                          validation.touched.startDate && validation.errors.startDate ? true : false
                        }
                      />
                      {validation.touched.startDate && validation.errors.startDate ? (
                        <FormFeedback type="invalid">{validation.errors.startDate}</FormFeedback>
                      ) : null}
                    </div>
                    
                    <div className="mb-3">
                      <Label className="form-label">Bitiş Tarihi</Label>
                      <Input
                        name="finishDate"
                        type="date"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.finishDate || ""}
                        invalid={
                          validation.touched.finishDate && validation.errors.finishDate ? true : false
                        }
                      />
                      {validation.touched.finishDate && validation.errors.finishDate ? (
                        <FormFeedback type="invalid">{validation.errors.finishDate}</FormFeedback>
                      ) : null}
                    </div>
                    
                            <div className="d-flex">
                              <label htmlFor="resume">Aktif Mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  className="switch"
                                  defaultChecked={getIsActive}
                                  onChange={() =>
                                    setIsActive(!getIsActive)
                                  }
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div>


                          </Col>
                        </Row>







                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
