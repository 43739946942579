import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER,

  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  GET_RESERVATION,
  GET_RESERVATION_SUCCESS,

  GET_CAMPAIN_HISTORY,
  GET_CAMPAIN_HISTORY_SUCCESS,
  ADD_CAMPAIN_HISTORY,
  ADD_CAMPAIN_HISTORY_SUCCESS,
  DELETE_CAMPAIN_HISTORY_SUCCESS,
  DELETE_CAMPAIN_HISTORY,
  UPDATE_CAMPAIN_HISTORY_SUCCESS,
  UPDATE_CAMPAIN_HISTORY,


  GET_SSS,
  GET_SSS_SUCCESS,
  ADD_SSS,
  ADD_SSS_SUCCESS,
  DELETE_SSS_SUCCESS,
  DELETE_SSS,
  UPDATE_SSS_SUCCESS,
  UPDATE_SSS,



  GET_CAMPAINPERIOD,
  GET_CAMPAINPERIOD_SUCCESS,
  ADD_CAMPAINPERIOD,
  ADD_CAMPAINPERIOD_SUCCESS,
  DELETE_CAMPAINPERIOD_SUCCESS,
  DELETE_CAMPAINPERIOD,
  UPDATE_CAMPAINPERIOD_SUCCESS,
  UPDATE_CAMPAINPERIOD,


  GET_SECTOR,
  GET_SECTOR_SUCCESS,
  ADD_SECTOR,
  ADD_SECTOR_SUCCESS,
  DELETE_SECTOR_SUCCESS,
  DELETE_SECTOR,
  UPDATE_SECTOR_SUCCESS,
  UPDATE_SECTOR,



  GET_SUBSECTOR,
  GET_SUBSECTOR_SUCCESS,
  ADD_SUBSECTOR,
  ADD_SUBSECTOR_SUCCESS,
  DELETE_SUBSECTOR_SUCCESS,
  DELETE_SUBSECTOR,
  UPDATE_SUBSECTOR_SUCCESS,
  UPDATE_SUBSECTOR,




  GET_JOB,
  GET_JOB_SUCCESS,
  ADD_JOB,
  ADD_JOB_SUCCESS,
  DELETE_JOB_SUCCESS,
  DELETE_JOB,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB,


  GET_CAMPAINCATEGORY,
  GET_CAMPAINCATEGORY_SUCCESS,
  ADD_CAMPAINCATEGORY,
  ADD_CAMPAINCATEGORY_SUCCESS,
  DELETE_CAMPAINCATEGORY_SUCCESS,
  DELETE_CAMPAINCATEGORY,
  UPDATE_CAMPAINCATEGORY_SUCCESS,
  UPDATE_CAMPAINCATEGORY,





  GET_CAMPAIN,
  GET_CAMPAIN_SUCCESS,
  ADD_CAMPAIN,
  ADD_CAMPAIN_SUCCESS,
  DELETE_CAMPAIN_SUCCESS,
  DELETE_CAMPAIN,
  UPDATE_CAMPAIN_SUCCESS,
  UPDATE_CAMPAIN,




  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  ADD_COMPANY,
  ADD_COMPANY_SUCCESS,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY,




  GET_FEEDBACK,
  GET_FEEDBACK_SUCCESS,
  ADD_FEEDBACK,
  ADD_FEEDBACK_SUCCESS,
  DELETE_FEEDBACK_SUCCESS,
  DELETE_FEEDBACK,
  UPDATE_FEEDBACK_SUCCESS,
  UPDATE_FEEDBACK,

  GET_BLOGCAT,
  GET_BLOGCAT_SUCCESS,
  ADD_BLOGCAT,
  ADD_BLOGCAT_SUCCESS,
  DELETE_BLOGCAT_SUCCESS,
  DELETE_BLOGCAT,
  UPDATE_BLOGCAT_SUCCESS,
  UPDATE_BLOGCAT,



  
  GET_BLOGSUBCAT,
  GET_BLOGSUBCAT_SUCCESS,
  ADD_BLOGSUBCAT,
  ADD_BLOGSUBCAT_SUCCESS,
  DELETE_BLOGSUBCAT_SUCCESS,
  DELETE_BLOGSUBCAT,
  UPDATE_BLOGSUBCAT_SUCCESS,
  UPDATE_BLOGSUBCAT,


  GET_ADVERT,
  GET_ADVERT_SUCCESS,
  ADD_ADVERT,
  ADD_ADVERT_SUCCESS,
  DELETE_ADVERT_SUCCESS,
  DELETE_ADVERT,
  UPDATE_ADVERT_SUCCESS,
  UPDATE_ADVERT,

  GET_AVAILABLE,
  GET_AVAILABLE_SUCCESS,
  ADD_AVAILABLE,
  ADD_AVAILABLE_SUCCESS,
  DELETE_AVAILABLE_SUCCESS,
  DELETE_AVAILABLE,
  UPDATE_AVAILABLE_SUCCESS,
  UPDATE_AVAILABLE,


  GET_OFFER,
  GET_OFFER_SUCCESS,
  ADD_OFFER,
  ADD_OFFER_SUCCESS,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER,


 
  DELETE_USER_FAIL,
  GET_POPUP,
  GET_WORKSHOP,
  GET_CourseSchedulesMega,
  GET_WorkshopSessionMega,
  GET_TeacherMega,
  GET_EVENT,
  GET_DYNAMICFORM,
  GET_SLIDER,
  GET_PROJECTSNEW,
  GET_NEWS,
  GET_MEGA,
  GET_CONTACT,
  GET_ABOUT,
  GET_AUTHORITY,
  GET_WORKSHOP2,
  GET_WORKSHOP2_SUCCESS,
  GET_GALLERY,
  GET_GALLERY_SUCCESS,
  GET_REFERANCES,
  ADD_REFERANCES,
  GET_SUBPROJECT,
  UPDATE_AUTH,
  UPDATE_WORKSHOP2,
  UPDATE_SUBDOMAIN_SUCCESS,
  UPDATE_CONTACT_SUCCESS,

  UPDATE_CONTACT,

  UPDATE_RESERVATION_SUCCESS,
  UPDATE_RESERVATION,

  UPDATE_ABOUT_SUCCESS,
  UPDATE_ABOUT,
  UPDATE_SUCCESS_COURSESCHEDULES,
  UPDATE_WORKSHOPSESSION_SUCCESS,
  UPDATE_WORKSHOP2_SUCCESS,
  UPDATE_GALLERY,
  ADD_LESSONS_TIME,

  UPDATE_LESSONS_TIME,
  GET_TEACHER_WORKSHOP,
  GET_REFERANCES_SUCCESS,
  ADD_WORKSHOP2,
  ADD_APPSETTING,
  ADD_WORKSHOPSUBDOMAIN,
  ADD_COURSE,
  ADD_MEGA,
  ADD_NEWS,

  ADD_PROJECT,
  ADD_SLIDER,
  ADD_SLIDER_SUCCESS,
  ADD_PRODUCTCATEGORY,
 
  ADD_BLOG,

  ADD_POPUP,
  UPDATE_NEWS,
  UPDATE_PROJECT,
  UPDATE_SLIDER,

  UPDATE_TEACHER,
  UPDATE_WORKSHOPSESSION,
  UPDATE_COURSESCHEDULES,
  UPDATE_SUBDOMAIN,
  UPDATE_POPUP,
  GET_POPUP_SUCCESS,
  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOPSESSION_SUCCESS,
  GET_APPLICATION_SUCCESS,
  GET_SLIDER_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_MEGA_SUCCESS,
  GET_TEACHER_SUCCESS,
  GET_DYNAMIC_SUCCESS,
  GET_AUTH_SUCCESS,
  GET_AUTHUPDATE_SUCCESS,
  UPDATE_SUCCESS_TEACHER,
  UPDATE_SLIDER_SUCCESS,
  UPDATE_SUCCESS_PROJECT,
  UPDATE_SUCCESS_NEWS,
  UPDATE_SUCCESS_MEGA,
  UPDATE_MEGA,
  UPDATE_SUCCESS_GALLERY,

  UPDATE_REFERANCES,
  UPDATE_REFERANCES_SUCCESS,

  GET_SUBPROJECT_SUCCESS,
  GET_TEACHERWORKSHOP_SUCCESS,

  ADD_LESSONSTIME_SUCCESS,
  ADD_REFERANCES_SUCCESS,
  UPDATE_LESSONSTIME_SUCCESS,

  GET_CLASSATTENDANCE,
  GET_CLASSATTENDANCE_SUCCESS,

  GET_CLASSES,
  GET_CLASSES_SUCCESS,
  UPDATE_DYNAMICFORM,
  UPDATE_DYNAMICFORM_SUCCESS,
  ADD_DYNAMIC_FORM,
  ADD_DYNAMICFORM_SUCCESS,
  GET_DYNAMICFORM_EXTRA,
  GET_DYNAMICFORMEXTRA_SUCCESS,
  GET_DYNAMICFORM_EXTRA_EXTRA,
  GET_DYNAMICFORMEXTRAEXTRA_SUCCESS,

  DELETE_MEGA,
  ADD_MEGA_SUCCESS,
  DELETE_MEGA_SUCCESS,

  DELETE_NEWS_SUCCESS,
  DELETE_NEWS,


  DELETE_POPUP,
  DELETE_POPUP_SUCCESS,

  DELETE_REFERANCES,
  DELETE_REFERANCES_SUCCESS,

  ADD_GALLERY,
  ADD_GALLERY_SUCCESS,

  ADD_USER_SUCCESS,

  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,

  ADD_RESERVATION,
  ADD_RESERVATION_SUCCESS,


  DELETE_RESERVATION,
  DELETE_RESERVATION_SUCCESS,

  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,


  DELETE_USER_SUCCESS,

  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,


  DELETE_GALLERY,
  DELETE_GALLERY_SUCCESS,

  ADD_GALLERYMULTIPLE,
  ADD_GALLERYMULTIPLE_SUCCESS,

  DELETE_COURSESCHEDULES,
  DELETE_COURSESCHEDULES_SUCCESS,

  DELETE_DYNAMICFORMEXTRA,
  DELETE_DYNAMICFORMEXTRA_SUCCESS,

  DELETE_DYNAMICFORM,
  DELETE_DYNAMICFORM_SUCCESS,

  DELETE_SLIDER,
  DELETE_SLIDER_SUCCESS,



  DELETE_BLOG,
  DELETE_BLOG_SUCCESS,

  DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  DELETE_DYNAMICFORMEXTRAEXTRA,
  GET_EDUCATION, GET_EDUCATION_SUCCESS,

  GET_SUBEDUCATION,
  GET_SUBEDUCATION_SUCCESS,


  GET_PRODUCTCATEGORY,
  GET_PRODUCTCATEGORY_SUCCESS,

  ADD_EDUCATION,
  ADD_EDUCATION_SUCCESS,

  UPDATE_EDUCATION_SUCCESS,
  UPDATE_EDUCATION,


  UPDATE_PRODUCTCATEGORY_SUCCESS,
  UPDATE_PRODUCTCATEGORY,

  ADD_SUBEDUCATION_SUCCESS,
  ADD_SUBEDUCATION,

  UPDATE_SUBEDUCATION,
  UPDATE_SUBEDUCATION_SUCCESS,

  DELETE_SUBEDUCATION,
  DELETE_SUBEDUCATION_SUCCESS,

  UPDATE_BLOG,
  UPDATE_BLOG_SUCCESS,

  DELETE_PRODUCTCATEGORY,
  DELETE_PRODUCTCATEGORY_SUCCESS,

  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_SLİDERS,
  GET_SLİDERS_SUCCESS,
  GET_BLOGS,
  GET_BLOGS_SUCCESS,
  ADD_PRODUCTCATEGORY_SUCCESS,
  ADD_BLOG_SUCCESS,
  GET_ABOUT_SUCCESS,

  GET_CAMPAINCOMPANY_HISTORY,
  GET_CAMPAIN_HISTORYCOMPANY_SUCCESS,

  ADD_CAMPAINCOMPANY_HISTORY,
ADD_CAMPAINCOMPANY_HISTORY_SUCCESS,
UPDATE_CAMPAINCOMPANY_HISTORY,
UPDATE_CAMPAINCOMPANY_HISTORY_SUCCESS,
DELETE_CAMPAINCOMPANY_HISTORY,
DELETE_CAMPAINCOMPANY_HISTORY_SUCCESS,

ADD_Authority,
ADD_Authority_SUCCESS,

ADD_NEW_USER_SUCCESS,

DELETE_AUTH,
DELETE_AUTH_SUCCESS,
ADD_USER_SUCCESS1,


GET_FEEDBACKCATEGORY,
GET_FEEDBACKCATEGORY_SUCCESS,
UPDATE_FEEDBACKCATEGORY,
UPDATE_FEEDBACKCATEGORY_SUCCESS,
ADD_FEEDBACKCATEGORY,
ADD_FEEDBACKCATEGORY_SUCCESS,

DELETE_FEEDBACKCATEGORY,
DELETE_FEEDBACKCATEGORY_SUCCESS
} from "./actionTypes"

export const getBlogCat= teacher => ({
  type: GET_BLOGCAT,
  payload: teacher,
})


export const getBlogCatSuccess = teacher => ({
  type: GET_BLOGCAT_SUCCESS,
  payload: teacher,
})

export const addFeedbackCategory = user => ({
  type: ADD_FEEDBACKCATEGORY,
  payload: user,
})

export const addFeedbackCategorySuccess = user => ({
  type: ADD_FEEDBACKCATEGORY_SUCCESS,
  payload: user,
})

export const updateFeedbackCategory= user => ({
  type: UPDATE_FEEDBACKCATEGORY,
  payload: user,
})
export const updateFeedbackCategorySuccess  = user => ({
  type: UPDATE_FEEDBACKCATEGORY_SUCCESS,
  payload: user,
})

export const updateBlogCat= user => ({
  type: UPDATE_BLOGCAT,
  payload: user,
})
export const updateBlogCatSuccess  = user => ({
  type: UPDATE_BLOGCAT_SUCCESS,
  payload: user,
})


export const deleteBlogCat= user => ({
  type: DELETE_BLOGCAT,
  payload: user,
})

export const deleteBlogCatSuccess = user => ({
  type: DELETE_BLOGCAT_SUCCESS,
  payload: user,
})

export const addBlogCat = user => ({
  type: ADD_BLOGCAT,
  payload: user,
})

export const addBlogCatSuccess = user => ({
  type: ADD_BLOGCAT_SUCCESS,
  payload: user,
})



export const getCampainHistoryCompany= teacher => ({
  type: GET_CAMPAINCOMPANY_HISTORY,
  payload: teacher,
})


export const getCampainHistorySuccessCompany = teacher => ({
  type: GET_CAMPAIN_HISTORYCOMPANY_SUCCESS,
  payload: teacher,
})



export const addCampainHistoryCompany = user => ({
  type: ADD_CAMPAINCOMPANY_HISTORY,
  payload: user,
})

export const addCampainHistoryCompanySuccess = user => ({
  type: ADD_CAMPAINCOMPANY_HISTORY_SUCCESS,
  payload: user,
})


export const updateCampainHistoryCompany= user => ({
  type: UPDATE_CAMPAINCOMPANY_HISTORY,
  payload: user,
})
export const updateCampainHistoryCompanySuccess  = user => ({
  type: UPDATE_CAMPAINCOMPANY_HISTORY_SUCCESS,
  payload: user,
})

export const deleteFeedBackCategory= user => ({
  type: DELETE_FEEDBACKCATEGORY,
  payload: user,
})

export const deleteFeedBackCategorySuccess = user => ({
  type: DELETE_FEEDBACKCATEGORY_SUCCESS,
  payload: user,
})

export const deleteAuth= user => ({
  type: DELETE_AUTH,
  payload: user,
})

export const deleteAuthSuccess = user => ({
  type: DELETE_AUTH_SUCCESS,
  payload: user,
})

export const deleteCampainHistoryCompany= user => ({
  type: DELETE_CAMPAINCOMPANY_HISTORY,
  payload: user,
})

export const deleteCampainHistoryCompanySuccess = user => ({
  type: DELETE_CAMPAINCOMPANY_HISTORY_SUCCESS,
  payload: user,
})




export const getCampainHistory= teacher => ({
  type: GET_CAMPAIN_HISTORY,
  payload: teacher,
})


export const getCampainHistorySuccess = teacher => ({
  type: GET_CAMPAIN_HISTORY_SUCCESS,
  payload: teacher,
})

export const updateCampainHistory= user => ({
  type: UPDATE_CAMPAIN_HISTORY,
  payload: user,
})
export const updateCampainHistorySuccess  = user => ({
  type: UPDATE_CAMPAIN_HISTORY_SUCCESS,
  payload: user,
})


export const deleteCampainHistory= user => ({
  type: DELETE_CAMPAIN_HISTORY,
  payload: user,
})

export const deleteCampainHistorySuccess = user => ({
  type: DELETE_CAMPAIN_HISTORY_SUCCESS,
  payload: user,
})

export const addAuthority = user => ({
  type: ADD_Authority,
  payload: user,
})

export const addAuthoritySuccess = user => ({
  type: ADD_Authority_SUCCESS,
  payload: user,
})



export const addCampainHistory = user => ({
  type: ADD_CAMPAIN_HISTORY,
  payload: user,
})

export const addCampainHistorySuccess = user => ({
  type: ADD_CAMPAIN_HISTORY_SUCCESS,
  payload: user,
})









export const getBlogSubCat= teacher => ({
  type: GET_BLOGSUBCAT,
  payload: teacher,
})


export const getBlogSubCatSuccess = teacher => ({
  type: GET_BLOGSUBCAT_SUCCESS,
  payload: teacher,
})

export const updateBlogSubCat= user => ({
  type: UPDATE_BLOGSUBCAT,
  payload: user,
})
export const updateBlogSubCatSuccess  = user => ({
  type: UPDATE_BLOGSUBCAT_SUCCESS,
  payload: user,
})


export const deleteBlogSubCat= user => ({
  type: DELETE_BLOGSUBCAT,
  payload: user,
})

export const deleteBlogSubCatSuccess = user => ({
  type: DELETE_BLOGSUBCAT_SUCCESS,
  payload: user,
})

export const addBlogSubCat = user => ({
  type: ADD_BLOGSUBCAT,
  payload: user,
})

export const addBlogSubCatSuccess = user => ({
  type: ADD_BLOGSUBCAT_SUCCESS,
  payload: user,
})





export const getCampainPeriod= teacher => ({
  type: GET_CAMPAINPERIOD,
  payload: teacher,
})


export const getCampainPeriodSuccess = teacher => ({
  type: GET_CAMPAINPERIOD_SUCCESS,
  payload: teacher,
})

export const updateCampainPeriod= user => ({
  type: UPDATE_CAMPAINPERIOD,
  payload: user,
})
export const updateCampainPeriodSuccess  = user => ({
  type: UPDATE_CAMPAINPERIOD_SUCCESS,
  payload: user,
})


export const deleteCampainPeriod= user => ({
  type: DELETE_CAMPAINPERIOD,
  payload: user,
})

export const deleteCampainPeriodSuccess = user => ({
  type: DELETE_CAMPAINPERIOD_SUCCESS,
  payload: user,
})

export const addCampainPeriod = user => ({
  type: ADD_CAMPAINPERIOD,
  payload: user,
})

export const addCampainPeriodSuccess = user => ({
  type: ADD_CAMPAINPERIOD_SUCCESS,
  payload: user,
})








export const getAdvert= teacher => ({
  type: GET_ADVERT,
  payload: teacher,
})


export const getAdvertSuccess = teacher => ({
  type: GET_ADVERT_SUCCESS,
  payload: teacher,
})

export const updateAdvert= user => ({
  type: UPDATE_ADVERT,
  payload: user,
})
export const updateAdvertSuccess  = user => ({
  type: UPDATE_ADVERT_SUCCESS,
  payload: user,
})


export const deleteAdvert= user => ({
  type: DELETE_ADVERT,
  payload: user,
})

export const deleteAdvertSuccess = user => ({
  type: DELETE_ADVERT_SUCCESS,
  payload: user,
})

export const addAdvert = user => ({
  type: ADD_ADVERT,
  payload: user,
})

export const addAdvertSuccess = user => ({
  type: ADD_ADVERT_SUCCESS,
  payload: user,
})








export const getAvailable= (startDate,finishDate) => ({
  type: GET_AVAILABLE,
  startDate: startDate,
  finishDate:finishDate
})


export const getAvailableSuccess = teacher => ({
  type: GET_AVAILABLE_SUCCESS,
  payload: teacher,
})



export const updateAvailable= user => ({
  type: UPDATE_AVAILABLE,
  payload: user,
})
export const updateAvailableSuccess  = user => ({
  type: UPDATE_AVAILABLE_SUCCESS,
  payload: user,
})


export const deleteAvailable = user => ({
  type: DELETE_AVAILABLE,
  payload: user,
})

export const deleteAvailableSuccess = user => ({
  type: DELETE_AVAILABLE_SUCCESS,
  payload: user,
})

export const addAvailable = user => ({
  type: ADD_AVAILABLE,
  payload: user,
})

export const addAvailableSuccess = user => ({
  type: ADD_AVAILABLE_SUCCESS,
  payload: user,
})



export const getFeedBackCategory= teacher => ({
  type: GET_FEEDBACKCATEGORY,
  payload: teacher,
})


export const getFeedBackCategorySuccess = teacher => ({
  type: GET_FEEDBACKCATEGORY_SUCCESS,
  payload: teacher,
})



export const getFeedBack= teacher => ({
  type: GET_FEEDBACK,
  payload: teacher,
})


export const getFeedBackSuccess = teacher => ({
  type: GET_FEEDBACK_SUCCESS,
  payload: teacher,
})



export const updateFeedBack= user => ({
  type: UPDATE_FEEDBACK,
  payload: user,
})
export const updateFeedBackSuccess  = user => ({
  type: UPDATE_FEEDBACK_SUCCESS,
  payload: user,
})


export const deleteFeedBack = user => ({
  type: DELETE_FEEDBACK,
  payload: user,
})

export const deleteFeedBackSuccess = user => ({
  type: DELETE_FEEDBACK_SUCCESS,
  payload: user,
})

export const addFeedBack = user => ({
  type: ADD_FEEDBACK,
  payload: user,
})

export const addFeedBackSuccess = user => ({
  type: ADD_FEEDBACK_SUCCESS,
  payload: user,
})




export const getSubSector= teacher => ({
  type: GET_SUBSECTOR,
  payload: teacher,
})


export const getSubSectorSuccess = teacher => ({
  type: GET_SUBSECTOR_SUCCESS,
  payload: teacher,
})



export const updateSubSector= user => ({
  type: UPDATE_SUBSECTOR,
  payload: user,
})
export const updateSubSectorSuccess  = user => ({
  type: UPDATE_SUBSECTOR_SUCCESS,
  payload: user,
})


export const deleteSubSector = user => ({
  type: DELETE_SUBSECTOR,
  payload: user,
})

export const deleteSubSectorSuccess = user => ({
  type: DELETE_SUBSECTOR_SUCCESS,
  payload: user,
})

export const addSubSector = user => ({
  type: ADD_SUBSECTOR,
  payload: user,
})

export const addSubSectorSuccess = user => ({
  type: ADD_SUBSECTOR_SUCCESS,
  payload: user,
})











export const getSector= teacher => ({
  type: GET_SECTOR,
  payload: teacher,
})


export const getSectorSuccess = teacher => ({
  type: GET_SECTOR_SUCCESS,
  payload: teacher,
})



export const updateSector= user => ({
  type: UPDATE_SECTOR,
  payload: user,
})
export const updateSectorSuccess  = user => ({
  type: UPDATE_SECTOR_SUCCESS,
  payload: user,
})


export const deleteSector = user => ({
  type: DELETE_SECTOR,
  payload: user,
})

export const deleteSectorSuccess = user => ({
  type: DELETE_SECTOR_SUCCESS,
  payload: user,
})

export const addSector = user => ({
  type: ADD_SECTOR,
  payload: user,
})

export const addSectorSuccess = user => ({
  type: ADD_SECTOR_SUCCESS,
  payload: user,
})







export const getCampainCategory= teacher => ({
  type: GET_CAMPAINCATEGORY,
  payload: teacher,
})


export const getCampainCategorySuccess = teacher => ({
  type: GET_CAMPAINCATEGORY_SUCCESS,
  payload: teacher,
})



export const updateCampainCategory= user => ({
  type: UPDATE_CAMPAINCATEGORY,
  payload: user,
})
export const updateCampainCategorySuccess  = user => ({
  type: UPDATE_CAMPAINCATEGORY_SUCCESS,
  payload: user,
})


export const deleteCampainCategory = user => ({
  type: DELETE_CAMPAINCATEGORY,
  payload: user,
})

export const deleteCampainCategorySuccess = user => ({
  type: DELETE_CAMPAINCATEGORY_SUCCESS,
  payload: user,
})

export const addCampainCategory = user => ({
  type: ADD_CAMPAINCATEGORY,
  payload: user,
})

export const addCampainCategorySuccess = user => ({
  type: ADD_CAMPAINCATEGORY_SUCCESS,
  payload: user,
})


export const getJob= teacher => ({
  type: GET_JOB,
  payload: teacher,
})


export const getJobSuccess = teacher => ({
  type: GET_JOB_SUCCESS,
  payload: teacher,
})



export const updateJob= user => ({
  type: UPDATE_JOB,
  payload: user,
})
export const updateJobSuccess  = user => ({
  type: UPDATE_JOB_SUCCESS,
  payload: user,
})


export const deleteJob = user => ({
  type: DELETE_JOB,
  payload: user,
})

export const deleteJobSuccess = user => ({
  type: DELETE_JOB_SUCCESS,
  payload: user,
})

export const addJob = user => ({
  type: ADD_JOB,
  payload: user,
})

export const addJobSuccess = user => ({
  type: ADD_JOB_SUCCESS,
  payload: user,
})












export const getCampain= teacher => ({
  type: GET_CAMPAIN,
  payload: teacher,
})


export const getCampainSuccess = teacher => ({
  type: GET_CAMPAIN_SUCCESS,
  payload: teacher,
})

export const updateCampain= user => ({
  type: UPDATE_CAMPAIN,
  payload: user,
})
export const updateCampainSuccess  = user => ({
  type: UPDATE_CAMPAIN_SUCCESS,
  payload: user,
})


export const deleteCampain = user => ({
  type: DELETE_CAMPAIN,
  payload: user,
})

export const deleteCampainSuccess = user => ({
  type: DELETE_CAMPAIN_SUCCESS,
  payload: user,
})

export const addCampain = user => ({
  type: ADD_CAMPAIN,
  payload: user,
})

export const addCampainSuccess = user => ({
  type: ADD_CAMPAIN_SUCCESS,
  payload: user,
})


export const getCompany= teacher => ({
  type: GET_COMPANY,
  payload: teacher,
})


export const getCompanySuccess = teacher => ({
  type: GET_COMPANY_SUCCESS,
  payload: teacher,
})

export const updateCompany= user => ({
  type: UPDATE_COMPANY,
  payload: user,
})
export const updateCompanySuccess  = user => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: user,
})


export const deleteCompany = user => ({
  type: DELETE_COMPANY,
  payload: user,
})

export const deleteCompanySuccess = (user,user2) => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: user,
})

export const addCompany = user => ({
  type: ADD_COMPANY,
  payload: user,
})

export const addCompanySuccess = user => ({
  type: ADD_COMPANY_SUCCESS,
  payload: user,
})











export const getOffer= teacher => ({
  type: GET_OFFER,
  payload: teacher,
})


export const getOfferSuccess = teacher => ({
  type: GET_OFFER_SUCCESS,
  payload: teacher,
})



export const updateOffer= user => ({
  type: UPDATE_OFFER,
  payload: user,
})
export const updateOfferSuccess  = user => ({
  type: UPDATE_OFFER_SUCCESS,
  payload: user,
})


export const deleteOffer = user => ({
  type: DELETE_OFFER,
  payload: user,
})

export const deleteOfferSuccess = user => ({
  type: DELETE_OFFER_SUCCESS,
  payload: user,
})

export const addOffer = user => ({
  type: ADD_OFFER,
  payload: user,
})

export const addOfferSuccess = user => ({
  type: ADD_OFFER_SUCCESS,
  payload: user,
})






export const deleteSubEducation = user => ({
  type: DELETE_SUBEDUCATION,
  payload: user,
})

export const deleteProductCategorySuccess = user => ({
  type: DELETE_PRODUCTCATEGORY_SUCCESS,
  payload: user,
})



export const deleteProductCategory = user => ({
  type: DELETE_PRODUCTCATEGORY,
  payload: user,
})



export const deleteReservationSuccess = user => ({
  type: DELETE_RESERVATION_SUCCESS,
  payload: user,
})



export const deleteReservation = user => ({
  type: DELETE_RESERVATION,
  payload: user,
})


export const addReservation = user => ({
  type: ADD_RESERVATION,
  payload: user,
})

export const addReservationSuccess = user => ({
  type: ADD_RESERVATION_SUCCESS,
  payload: user,
})



export const deleteSubEducationSuccess = user => ({
  type: DELETE_SUBEDUCATION_SUCCESS,
  payload: user,
})






export const addSubEducation = user => ({
  type: ADD_SUBEDUCATION,
  payload: user,
})

export const addSubEducationSuccess = user => ({
  type: ADD_SUBEDUCATION_SUCCESS,
  payload: user,
})


export const updateProduct = user => ({
  type: UPDATE_PRODUCT,
  payload: user,
})


export const updateProductSuccess = user => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: user,
})



export const deleteProduct = user => ({
  type: DELETE_PRODUCT,
  payload: user,
})

export const deleteProductSuccess = (user, ID) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: user,
  ID: ID
})





export const addProduct = user => ({
  type: ADD_PRODUCT,
  payload: user,
})

export const addProductSuccess = user => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: user,
})



export const updateSubEducation = user => ({
  type: UPDATE_SUBEDUCATION,
  payload: user,
})


export const updateSubEducationSuccess = user => ({
  type: UPDATE_SUBEDUCATION_SUCCESS,
  payload: user,
})


export const updateBlog = user => ({
  type: UPDATE_BLOG,
  payload: user,
})


export const updateBlogSuccess = user => ({
  type: UPDATE_BLOG_SUCCESS,
  payload: user,
})

export const updateProductCategory = user => ({
  type: UPDATE_PRODUCTCATEGORY,
  payload: user,
})


export const updateProductCategorySuccess = user => ({
  type: UPDATE_PRODUCTCATEGORY_SUCCESS,
  payload: user,
})

export const updateEducationSuccess = user => ({
  type: UPDATE_EDUCATION_SUCCESS,
  payload: user,
})



export const updateEducation = user => ({
  type: UPDATE_EDUCATION,
  payload: user,
})


export const addEducation = user => ({
  type: ADD_EDUCATION,
  payload: user,
})

export const addEducationSuccess = user => ({
  type: ADD_EDUCATION_SUCCESS,
  payload: user,
})

export const addMegaSuccess = user => ({
  type: ADD_MEGA_SUCCESS,
  payload: user,
})

export const updateDynamicFormSuccess = user => ({
  type: UPDATE_DYNAMICFORM_SUCCESS,
  payload: user,
})


export const updateDynamicForm = user => ({
  type: UPDATE_DYNAMICFORM,
  payload: user,
})

export const getClasses = () => ({
  type: GET_CLASSES,
})


export const getDynamicFormExtraExtraSuccess = user => ({
  type: GET_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  payload: user,
})


export const getDynamicFormExtraSuccess = user => ({
  type: GET_DYNAMICFORMEXTRA_SUCCESS,
  payload: user,
})


export const getClassesSuccess = user => ({
  type: GET_CLASSES_SUCCESS,
  payload: user,
})

export const getClassAttendanceSuccess = user => ({
  type: GET_CLASSATTENDANCE_SUCCESS,
  payload: user,
})


export const updateLessonsTimeSuccess = user => ({
  type: UPDATE_LESSONSTIME_SUCCESS,
  payload: user,
})


export const getTeacherWorkshopSuccess = teacher => ({
  type: GET_TEACHERWORKSHOP_SUCCESS,
  payload: teacher,
})

export const getSubProjectSuccess = teacher => ({
  type: GET_SUBPROJECT_SUCCESS,
  payload: teacher,
})


export const getReferancesSuccess = teacher => ({
  type: GET_REFERANCES_SUCCESS,
  payload: teacher,
})


export const updateGallerySuccess = user => ({
  type: UPDATE_SUCCESS_GALLERY,
  payload: user,
})


export const getAuthority = () => ({
  type: GET_AUTHORITY,
})



export const updateWorkShop2Success = user => ({
  type: UPDATE_WORKSHOP2_SUCCESS,
  payload: user,
})

export const updateReferances = user => ({
  type: UPDATE_REFERANCES,
  payload: user,
})
export const updateMega = user => ({
  type: UPDATE_MEGA,
  payload: user,
})

export const updateMegaSuccess = news => ({
  type: UPDATE_SUCCESS_MEGA,
  payload: news,
})

export const updateNewsSuccess = news => ({
  type: UPDATE_SUCCESS_NEWS,
  payload: news,
})

export const updateSuccessProject = teacher => ({
  type: UPDATE_SUCCESS_PROJECT,
  payload: teacher,
})

export const updateSliderSuccess = teacher => ({
  type: UPDATE_SLIDER_SUCCESS,
  payload: teacher,
})


export const updateTeacherSuccess = teacher => ({
  type: UPDATE_SUCCESS_TEACHER,
  payload: teacher,
})

export const updateSuccessCourseSchedules = teacher => ({
  type: UPDATE_SUCCESS_COURSESCHEDULES,
  payload: teacher,
})

export const getSubEducation = teacher => ({
  type: GET_SUBEDUCATION,
  payload: teacher,
})

export const getSubEducationSuccess = teacher => ({
  type: GET_SUBEDUCATION_SUCCESS,
  payload: teacher,
})


export const getProduct = teacher => ({
  type: GET_PRODUCT,
  payload: teacher,
})


export const getProductSuccess = teacher => ({
  type: GET_PRODUCT_SUCCESS,
  payload: teacher,
})


export const getSliders = teacher => ({
  type: GET_SLİDERS,
  payload: teacher,
})


export const getSlidersSuccess = teacher => ({
  type: GET_SLİDERS_SUCCESS,
  payload: teacher,
})



export const getBlogs = teacher => ({
  type: GET_BLOGS,
  payload: teacher,
})


export const getBlogsSuccess = teacher => ({
  type: GET_BLOGS_SUCCESS,
  payload: teacher,
})




export const getReservation = teacher => ({
  type: GET_RESERVATION,
  payload: teacher,
})


export const getReservationSuccess = teacher => ({
  type: GET_RESERVATION_SUCCESS,
  payload: teacher,
})



export const updateReservation = user => ({
  type: UPDATE_RESERVATION,
  payload: user,
})
export const updateReservationSuccess  = user => ({
  type: UPDATE_RESERVATION_SUCCESS,
  payload: user,
})




export const getSSS = teacher => ({
  type: GET_SSS,
  payload: teacher,
})


export const getSSSSuccess = teacher => ({
  type: GET_SSS_SUCCESS,
  payload: teacher,
})



export const updateSSS = user => ({
  type: UPDATE_SSS,
  payload: user,
})
export const updateSSSSuccess  = user => ({
  type: UPDATE_SSS_SUCCESS,
  payload: user,
})



export const getProductCategory = teacher => ({
  type: GET_PRODUCTCATEGORY,
  payload: teacher,
})

export const getProductCategorySuccess = teacher => ({
  type: GET_PRODUCTCATEGORY_SUCCESS,
  payload: teacher,
})


export const getAuthUpdateSuccess = teacher => ({
  type: GET_AUTHUPDATE_SUCCESS,
  payload: teacher,
})

export const getAuthSuccess = teacher => ({
  type: GET_AUTH_SUCCESS,
  payload: teacher,
})
export const getDynamicSuccess = teacher => ({
  type: GET_DYNAMIC_SUCCESS,
  payload: teacher,
})

export const getTeacherSuccess = teacher => ({
  type: GET_TEACHER_SUCCESS,
  payload: teacher,
})

export const getGallerySuccess = mega => ({
  type: GET_GALLERY_SUCCESS,
  payload: mega,
})


export const getMegaSuccess = mega => ({
  type: GET_MEGA_SUCCESS,
  payload: mega,
})


export const getEducation = news => ({
  type: GET_EDUCATION,
  payload: news,
})


export const getEducationSuccess = news => ({
  type: GET_EDUCATION_SUCCESS,
  payload: news,
})


export const getWorkShop2 = news => ({
  type: GET_WORKSHOP2,
  payload: news,
})


export const getNewsSuccess = news => ({
  type: GET_NEWS_SUCCESS,
  payload: news,
})

export const getProjectSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})

export const getSliderSuccess = slider => ({
  type: GET_SLIDER_SUCCESS,
  payload: slider,
})

export const getApplicationSuccess = workshop => ({
  type: GET_APPLICATION_SUCCESS,
  payload: workshop,
})
export const getWorkshopSessionSuccess = workshop => ({
  type: GET_WORKSHOPSESSION_SUCCESS,
  payload: workshop,
})
export const getWorkshopSuccess = workshop => ({
  type: GET_WORKSHOP_SUCCESS,
  payload: workshop,
})


export const updateWorkShopSessionSuccess = user => ({
  type: UPDATE_WORKSHOPSESSION_SUCCESS,
  payload: user,
})

export const updateSubdomainSuccess = user => ({
  type: UPDATE_SUBDOMAIN_SUCCESS,
  payload: user,
})

export const updateAuth = user => ({
  type: UPDATE_AUTH,
  payload: user,
})

export const updateContact = user => ({
  type: UPDATE_CONTACT,
  payload: user,
})
export const updateContactSuccess  = user => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: user,
})


export const updateAbout= user => ({
  type: UPDATE_ABOUT,
  payload: user,
})
export const updateAboutSuccess  = user => ({
  type: UPDATE_ABOUT_SUCCESS,
  payload: user,
})

export const updateNews = user => ({
  type: UPDATE_NEWS,
  payload: user,
})


export const updateProject = user => ({
  type: UPDATE_PROJECT,
  payload: user,
})

export const updateSlider = user => ({
  type: UPDATE_SLIDER,
  payload: user,
})


export const getWorkshop2Success = popup => ({
  type: GET_WORKSHOP2_SUCCESS,
  payload: popup,
})


export const getPopupSuccess = popup => ({
  type: GET_POPUP_SUCCESS,
  payload: popup,
})


export const updateTeacher = user => ({
  type: UPDATE_TEACHER,
  payload: user,
})


export const updateWorkshop2 = user => ({
  type: UPDATE_WORKSHOP2,
  payload: user,
})


export const updateWorkshopSession = user => ({
  type: UPDATE_WORKSHOPSESSION,
  payload: user,
})



export const updateCourseSchedules = user => ({
  type: UPDATE_COURSESCHEDULES,
  payload: user,
})

export const updateSubdomain = user => ({
  type: UPDATE_SUBDOMAIN,
  payload: user,
})


export const updatePopup = user => ({
  type: UPDATE_POPUP,
  payload: user,
})


export const getClassAttendance = () => ({
  type: GET_CLASSATTENDANCE,
})


export const getTeacherWorkshopMega = () => ({
  type: GET_TEACHER_WORKSHOP,
})

export const getReferances = () => ({
  type: GET_REFERANCES,
})

export const getContactMega = () => ({
  type: GET_CONTACT,
})
export const getAbout = () => ({
  type: GET_ABOUT
})


export const getMegaMega = () => ({
  type: GET_MEGA,
})

export const getNews = () => ({
  type: GET_NEWS,
})

export const getSubProject = news => ({
  type: GET_SUBPROJECT,
  news,
})


export const getProjectsNew = () => ({
  type: GET_PROJECTSNEW,
})


export const getSlider = () => ({
  type: GET_SLIDER,
})

export const getDynamicForm = () => ({
  type: GET_DYNAMICFORM,
})


export const getEventMega = () => ({
  type: GET_EVENT,
})

export const getTeacherMega = () => ({
  type: GET_TeacherMega,
})

export const getWorkshopSessionMega = () => ({
  type: GET_WorkshopSessionMega,
})


export const getWorkShop = () => ({
  type: GET_WORKSHOP,
})

export const getPopup = users => ({
  type: GET_POPUP,
  users,
})


export const getCourseSchedulesMega = () => ({
  type: GET_CourseSchedulesMega,
})



export const getGallery = () => ({
  type: GET_GALLERY,
})

export const getUsers = users => ({
  type: GET_USERS,
  users,
})

export const getDynamicFormExtra = users => ({
  type: GET_DYNAMICFORM_EXTRA,
  users,
})

export const getDynamicFormExtraExtra = users => ({
  type: GET_DYNAMICFORM_EXTRA_EXTRA,
  users,
})


export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getAboutSuccess = users => ({
  type: GET_ABOUT_SUCCESS,
  payload: users,
})

export const addDynamicFormSuccess = user => ({
  type: ADD_DYNAMICFORM_SUCCESS,
  payload: user,
})

export const addDynamicForm = user => ({
  type: ADD_DYNAMIC_FORM,
  payload: user,
})

export const addLessonsTime = user => ({
  type: ADD_LESSONS_TIME,
  payload: user,
})

export const addReferances = user => ({
  type: ADD_REFERANCES,
  payload: user,
})

export const updateSuccessReferances = user => ({
  type: UPDATE_REFERANCES_SUCCESS,
  payload: user,
})

export const addUser = user => ({
  type: ADD_USER,
  payload: user,
})


export const addSSS = user => ({
  type: ADD_SSS,
  payload: user,
})
export const addSSSSuccess = user => ({
  type: ADD_SSS_SUCCESS,
  payload: user,
})


export const updateSuccessSSS = user => ({
  type: UPDATE_SSS_SUCCESS,
  payload: user,
})
export const addNewWorkshop2 = user => ({
  type: ADD_WORKSHOP2,
  payload: user,
})


export const addAppSetting = user => ({
  type: ADD_APPSETTING,
  payload: user,
})

export const addWorkshopSubdomain = user => ({
  type: ADD_WORKSHOPSUBDOMAIN,
  payload: user,
})

export const addCourse = user => ({
  type: ADD_COURSE,
  payload: user,
})

export const addNewMega = user => ({
  type: ADD_MEGA,
  payload: user,
})

export const addNews = user => ({
  type: ADD_NEWS,
  payload: user,
})

export const addProject = user => ({
  type: ADD_PROJECT,
  payload: user,
})

export const addSlider = user => ({
  type: ADD_SLIDER,
  payload: user,
})
export const addSliderSuccess = user => ({
  type: ADD_SLIDER_SUCCESS,
  payload: user,
})

export const addProductCategory = user => ({
  type: ADD_PRODUCTCATEGORY,
  payload: user,
})




export const addProductCategorySuccess = user => ({
  type: ADD_PRODUCTCATEGORY_SUCCESS,
  payload: user,
})




export const addBlog = user => ({
  type: ADD_BLOG,
  payload: user,
})
export const addBlogSuccess = user => ({
  type: ADD_BLOG_SUCCESS,
  payload: user,
})


export const addPopup = user => ({
  type: ADD_POPUP,
  payload: user,
})

export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})


export const addAuthSuccess = user => ({
  type: ADD_NEW_USER_SUCCESS,
  payload: user,
})






export const addSuccessLessonsTime = user => ({
  type: ADD_LESSONSTIME_SUCCESS,
  payload: user,
})


export const addSuccessReferances = user => ({
  type: ADD_REFERANCES_SUCCESS,
  payload: user,
})


export const addUserSuccess1 = user => ({
  type: ADD_USER_SUCCESS1,
  payload: user,
})


export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
})

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
})

export const updateLessonsTime = user => ({
  type: UPDATE_LESSONS_TIME,
  payload: user,
})

export const updateGallery = user => ({
  type: UPDATE_GALLERY,
  payload: user,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = (user,ID) => ({
  type: DELETE_USER_SUCCESS,
  payload: ID,
})


export const deleteSSS = user => ({
  type: DELETE_SSS,
  payload: user,
})

export const deleteSSSSuccess = user => ({
  type: DELETE_SSS_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})


export const deleteMega = user => ({
  type: DELETE_MEGA,
  payload: user,
})

export const deleteMegaSuccess = (user, ID) => ({
  type: DELETE_MEGA_SUCCESS,
  payload: user,
  ID: ID
})

export const deleteNews = user => ({
  type: DELETE_NEWS,
  payload: user,
})



export const deleteNewsSuccess = (user, ID) => ({
  type: DELETE_NEWS_SUCCESS,
  payload: user,
  ID: ID
})

export const deleteSlider = user => ({
  type: DELETE_SLIDER,
  payload: user,
})

export const deleteSliderSuccess = (user, ID) => ({
  type: DELETE_SLIDER_SUCCESS,
  payload: user,
  ID: ID
})


export const deleteBlog = user => ({
  type: DELETE_BLOG,
  payload: user,
})

export const deleteBlogSuccess = (user, ID) => ({
  type: DELETE_BLOG_SUCCESS,
  payload: user,
  ID: ID
})

export const deletePopup = user => ({
  type: DELETE_POPUP,
  payload: user,
})

export const deletePopupSuccess = (user, ID) => ({
  type: DELETE_POPUP_SUCCESS,
  payload: user,
  ID: ID
})


export const deleteReferances = user => ({
  type: DELETE_REFERANCES,
  payload: user,
})

export const deleteReferancesSuccess = (user, ID) => ({
  type: DELETE_REFERANCES_SUCCESS,
  payload: user,
  ID: ID
})

export const addGallerySuccess = user => ({
  type: ADD_GALLERY_SUCCESS,
  payload: user,
})

export const addGallery = user => ({
  type: ADD_GALLERY,
  payload: user,
})

export const deleteGallery = user => ({
  type: DELETE_GALLERY,
  payload: user,
})

export const deleteGallerySuccess = (user, ID) => ({
  type: DELETE_GALLERY_SUCCESS,
  payload: user,
  ID: ID
})


export const addGalleryMultipleSuccess = user => ({
  type: ADD_GALLERYMULTIPLE_SUCCESS,
  payload: user,
})

export const addGalleryMultiple = user => ({
  type: ADD_GALLERYMULTIPLE,
  payload: user,
})

export const deleteCourseSchedules = user => ({
  type: DELETE_COURSESCHEDULES,
  payload: user,
})

export const deleteCourseSchedulesSuccess = (user, ID) => ({
  type: DELETE_COURSESCHEDULES_SUCCESS,
  payload: user,
  ID: ID
})

export const deleteDynamicFormExtra = user => ({
  type: DELETE_DYNAMICFORMEXTRA,
  payload: user,
})

export const deleteDynamicExtraFormSuccess = (user, ID) => ({
  type: DELETE_DYNAMICFORMEXTRA_SUCCESS,
  payload: user,
  ID: ID
})

export const deleteDynamicForm = user => ({
  type: DELETE_DYNAMICFORM,
  payload: user,
})

export const deleteDynamicFormSuccess = (user, ID) => ({
  type: DELETE_DYNAMICFORM_SUCCESS,
  payload: user,
  ID: ID
})



export const deleteDynamicFormExtraExtra = user => ({
  type: DELETE_DYNAMICFORMEXTRAEXTRA,
  payload: user,
})

export const deleteDynamicExtraExtraFormSuccess = (user, ID) => ({
  type: DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  payload: user,
  ID: ID
})
