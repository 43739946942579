import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import TableContainerAuth from "../../../components/Common/TableContainerAuth";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname} from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getAuthority as onGetAuthority,
  addNewUser as onAddNewUser,
  updateAuth as onUpdateAuth,
  deleteAuth as onDeleteAuth,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import { Status } from "../Users/contactlistCol";

const ContactsList = props => {

  //meta title
  document.title = "Kullanıcı Grupları | Kupon ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getIsActive, setIsActive] = useState(false);


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      authName: (contactEdit && contactEdit.GroupType_Name) || "",
   
    },
    validationSchema: Yup.object({
      authName: Yup.string().required("Grup Adını Giriniz")
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          ID: contactEdit.ID,
          GroupType_Name:values.authName,
          IsActive:getIsActive
        };


        dispatch(onUpdateAuth(updateUser));
        setIsEdit(false);
        validation.resetForm();

      } else {
        const newUser = {
          GroupType_Name: values["authName"],
          IsActive:getIsActive
        };
        // save new user
        dispatch(onAddNewUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.authlist,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalAuth, setModalAuth] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isEditAuth, setEditAuth] = useState(false);

  const [getDeleteList, setDeleteList] = useState([]);

  const [getAuthList, setAuthList] = useState([]);
  const [getAllAuth, setAllAuth] = useState([]);
  const [getUserAuth, setUserAuth] = useState([]);

  const _getSearchAuth = async (item) => {
    try {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getGroupTypeKuponSearch/${item.ID}`)
    .then((res) => {
      
      setUserAuth(res.data)

      _getAllAuth(res.data,item.ID)
    })
  } catch (err) {
    console.log("getSearchAuth",err)
  }
  }
  
  
  
  const _deleteUser= async () => {
    try {
  
   getDeleteList.map(async(item, index) => {
    

    axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteGroupUserKupon/${item.ID}`).then(async (res) => {

    
    console.log("lnscdkf",res)
   })

   console.log("klnsdlfk",item)
    })

    setTimeout(() => {
      _getSearchAuth(isEditAuth)
    }, 2000); // 2000 milisaniye (2 saniye) sonra çalışacak
   

    
  } catch (err) {
    console.log("_getAllAuth",err)
  }
  }

  const _selectUser= async () => {
    try {

  
   getAuthList.map(async(item, index) => {
    const newUser = {
      Users_ID: item.ID,
      GroupType_ID : isEditAuth.ID
      }

   axios.post('https://getjob.stechomeyazilim.info:5101/addGroupUserKupon/send', newUser)

 
   setTimeout(() => {
    _getSearchAuth(isEditAuth)
  }, 2000); // 2000 milisaniye (2 saniye) sonra çalışacak
   
 
   console.log("klnsdlfk",item)
    })


  } catch (err) {
    console.log("_getAllAuth",err)
  }
  }

  const _getAllAuth = async (data,ID) => {
    try {

      setAllAuth([])

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersKupon/select/`)
    .then((res) => {

      console.log("lk1cm1232lk3", data.length)


      if(res.data.length > 0){

        res.data.map(async(item, index) => {


                
      await axios.get(`https://getjob.stechomeyazilim.info:5101/getGroupTypeKuponSearch2/${ID}/${item.ID}`)
      .then((resData) => {

        console.log("lnsdkf",resData.data)
        if(resData.data.length == 0){

          console.log("deneme",item)

          setAllAuth(oldArray => [...oldArray,item] );
        }
      })

       
         
        })
        
      }else{
        setAllAuth(res.data)
      }
    
     
    })
  } catch (err) {
    console.log("_getAllAuth",err)
  }
  }
  
  

  const openDeleteUser = (cell) => {

    console.log("cell1231123213c",getDeleteList)
    let filterData = getDeleteList.filter((x)=> x.ID == cell.row.original.ID)


    if (filterData.length == 0){
      setDeleteList(oldArray => [...oldArray,cell.row.original] );
    }else{

    }

  };

  const openClick = (cell) => {

    let filterData = getAuthList.filter((x)=> x.ID == cell.row.original.ID)


    if (filterData.length == 0){
      setAuthList(oldArray => [...oldArray,cell.row.original] );
    }else{

    }

  };

  const openClickUser = (cell) => {

    let filterData = getAuthList.filter((x)=> x.ID == cell.row.original.ID )


    if (filterData.length == 0){
      setAuthList(oldArray => [...oldArray,cell.row.original] );
    }else{

    }

  };

  const columnsUserAuth = useMemo(
    () => [
      {
        Header: "#",
        Cell: cellProps => {
          return <input onClick={()=> openDeleteUser(cellProps)} type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Kul. Adı",
        accessor: "Users.NameSurname",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      }
    
    ],
    []
  );



  const columnsAuth = useMemo(
    () => [
      {
        Header: "#",
        Cell: cellProps => {
          return <input onClick={()=> openClick(cellProps)} type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Kullanıcılar",
        accessor: "NameSurname",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      }
    
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Grup Adı",
        accessor: "GroupType_Name",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
    
      {
        Header: "Aktif Mi?",
        accessor: "IsActive",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              
              
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserAuth(userData);
                }}
              >
                <div>Kullanıcılar</div>
                
                <UncontrolledTooltip placement="top" target="edittooltip">
                Kullanıcılar
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetAuthority());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleAuth = () => {
    setModalAuth(!modalAuth);
  };

  
  const handleUserAuth = (arg) => {
    const user = arg;

    setEditAuth(user);

    toggleAuth();

    _getSearchAuth(user)
  };


  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);
    setIsEdit(true);

    setIsActive(user.IsActive)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      console.log("lkmdsklf",contact.ID)
      dispatch(onDeleteAuth(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Kullanıcı Grupları" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddAuthList={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

             <Modal size="lg" isOpen={modalAuth} toggle={toggleAuth}>
                    <ModalHeader toggle={toggleAuth} tag="h4">
                     Kullanıcılar
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}>
                        
                        <Row className="d-flex gap-1">
                          
                          <Col xs={5}>          
                  <TableContainerAuth
                    columns={columnsAuth}
                    data={getAllAuth}
                    isGlobalFilter={false}
                    isAddUserList={false}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                         
                          </Col>

                          <Col xs={1}>
                         
                            <div className="text-end">
                              <button
                                onClick={()=> _deleteUser()}
                                type="submit"
                                className="btn btn-success save-user"
                              >
                            <i className="bx bx-chevrons-left"></i>

                              </button>
                            </div>

                          

                            <div className="text-end mt-2">
                              <button
                              onClick={()=> _selectUser()}
                                type="submit"
                                className="btn btn-success save-user"
                              >
                            <i className="bx bx-chevrons-right"></i>

                              </button>
                            </div>

                            </Col>

                          <Col xs={5}>
                            
                   <TableContainerAuth
                    columns={columnsUserAuth}
                    data={getUserAuth}
                    isGlobalFilter={false}
                    isAddUserList={false}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
       
        </Col>

                         

          
                        </Row>


                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kapat
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                  
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Grup Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>

                            <div className="mb-3">
                              <Label className="form-label">Grup Adı</Label>
                              <Input
                                name="authName"
                                label="Rol Adı"
                                type="text"
                                placeholder="Grup Adını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.authName || ""}
                                invalid={
                                  validation.touched.authName &&
                                    validation.errors.authName
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.authName &&
                                validation.errors.authName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.authName}
                                </FormFeedback>
                              ) : null}
                            </div>
                         
                          
                            <div className="d-flex">
                              <label htmlFor="resume">Aktif Mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  className="switch"
                                  defaultChecked={getIsActive}
                                  onChange={() =>
                                    setIsActive(!getIsActive)
                                  }
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div>
                          
                          </Col>
                        </Row>
                        <Row>

                  
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
